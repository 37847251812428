import {IHwCatalogService} from "../../service/IHwCatalogService";
export class AdspaceTopContainerController {
    static  $inject = ['hwCatalogService', '$window'];

    constructor(public hwCatalogService:IHwCatalogService, private $window:ng.IWindowService){
    }

    getHwCatalogTopAdspaceUrl():string {
        return this.hwCatalogService.getHwCatalogTopAdspaceUrl();
    }
}