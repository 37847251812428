import {IHwCatalogService, ServiceState} from '../../service/IHwCatalogService';
import {SortingValue} from '../../domain/SortingValue';
import {IOnInit} from "angular";

export class SortControlController implements IOnInit {

    // state
    state: ServiceState;

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService: IHwCatalogService) {
        this.state = hwCatalogService.getManagedState();
    }

    $onInit() {
        setTimeout(function (){
            const sortType: String = $("#selectSort").val().toString();
            const clickedSortType: SortingValue = self.getClickedSortValue(sortType);
            self.selectSort(clickedSortType);
        }, 100)
        const self = this;
        $("#selectSort").on("change", function () {
            const sortType: String = $(this).val().toString();
            const clickedSortType: SortingValue = self.getClickedSortValue(sortType);
            self.selectSort(clickedSortType);
        });
    }

    getClickedSortValue = (sortType: String): SortingValue => {
        const selectedSort = _.find(this.state.sorts, (sortingValue: SortingValue) => sortingValue.name === sortType.toString());
        return selectedSort as SortingValue;
    };

    selectSort = (sortType: SortingValue): void => {
        this.hwCatalogService.setFilterType('sorting');
        this.hwCatalogService.selectSort(sortType);
    };
}
    
