import { PriceBoxController } from './PriceBoxController';
export function createPriceBoxDirective(directiveName:string, htmlTemplate:string) {
    return function PriceBox():ng.IDirective {
        return {
            scope: {
                hardware: '=' + directiveName + 'Hardware',
                tariffExplicitlySet: '=' + directiveName + 'TariffExplicitlySet'
            },
            replace: true,
            template: htmlTemplate,
            controller: <any>PriceBoxController,
            controllerAs: 'priceBoxController'
        };
    }
}
