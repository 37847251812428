import {valueMetaInfo} from '@shop/common';
import {HwOnlyOrBundleSwitcherValue} from "./HwOnlyOrBundleSwitcherValue";

export class HwCatalogSwitcherStateValue {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogSwitcherStateValue', {
        selectedTargetGroupSwitcher: 'string',
        hwOnlyOrBundleSwitcherValue: 'HwOnlyOrBundleSwitcherValue'
    });

    selectedTargetGroupSwitcher:string;
    hwOnlyOrBundleSwitcherValue:HwOnlyOrBundleSwitcherValue;

    public copy():HwCatalogSwitcherStateValue{
        let clone = new HwCatalogSwitcherStateValue();
        let hwOnlyOrBundleSwitcherValueClone = new HwOnlyOrBundleSwitcherValue();

        clone.selectedTargetGroupSwitcher = this.selectedTargetGroupSwitcher;

        hwOnlyOrBundleSwitcherValueClone.hwOnlyOrBundleState = this.hwOnlyOrBundleSwitcherValue.hwOnlyOrBundleState;
        hwOnlyOrBundleSwitcherValueClone.showSwitcher = this.hwOnlyOrBundleSwitcherValue.showSwitcher;

        clone.hwOnlyOrBundleSwitcherValue = hwOnlyOrBundleSwitcherValueClone;

        return clone;
    }
}