import {valueMetaInfo} from '@shop/common';
import {TargetGroupValue} from "./TargetGroupValue";
export class TargetGroupSwitcherValue {

    static META_INFO = valueMetaInfo('hwCatalog:TargetGroupSwitcherValue', {
        defaultTargetGroup: 'TargetGroupValue',
        visibleTargetGroups: '[TargetGroupValue]'
    });

    defaultTargetGroup: TargetGroupValue;
    visibleTargetGroups: TargetGroupValue[];
}
