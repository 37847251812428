import { enumMetaInfo } from '@shop/common';
export class MarketingClassificationType {

    static META_INFO = enumMetaInfo('hwCatalog:MarketingClassificationType');

    private static _values:{ [s:string]:MarketingClassificationType; } = {};

    static forName(obj):MarketingClassificationType {
        argsContract(arguments, 'str | undef');
        if (obj === null) {
            return null;
        }
        return MarketingClassificationType._values[obj];
    }

    static values():MarketingClassificationType[] {
        return _.values(MarketingClassificationType._values);
    }

    static HERO_DEVICE = new MarketingClassificationType("HERO_DEVICE");
    static ORDINARY_DEVICE = new MarketingClassificationType("ORDINARY_DEVICE");

    constructor(public  name:string) {
        argsContract(arguments, 'str');
        MarketingClassificationType._values[name] = this;
    }

}
