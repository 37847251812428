import { CriteriaValue } from './CriteriaValue';
import { LogicType } from './LogicType';
import { valueMetaInfo } from '@shop/common';
export class CriteriaGroupValue {

    static META_INFO = valueMetaInfo('hwCatalog:CriteriaGroupValue', {
        name: 'string',
        title: 'string',
        criterias: '[CriteriaValue]',
        logicType: 'LogicType | undefined'
    });

    name:string;
    title:string;
    criterias:CriteriaValue[];
    logicType:LogicType;

    getSelectedValues():string[] {
        var selected = _.filter(this.criterias,
            (criteria) => criteria.selected
        );

        return _.map(
            selected,
            (criteria) => criteria.value
        );
    }
}
