import {Call, Link, PostCall, valueMetaInfo} from '@shop/common';
import {HwCatalogTabValue} from './HwCatalogTabValue';
import {SortingValue} from './SortingValue';
import {CriteriaValue} from './CriteriaValue';
import {CriteriaGroupValue} from './CriteriaGroupValue';
import {HardwareValue} from './HardwareValue';
import {DeviceTradeInPageValue} from "./DeviceTradeInPageValue";
import _ = require('underscore');
import {HwCatalogSelectedTabUrl} from './HwCatalogSelectedTabUrl';
import {LoginStatusTrackingValue} from "./LoginStatusTrackingValue";

export class HwCatalogPageValue {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogPageValue', {
        tabs: '[HwCatalogTabValue]',
        hardwareCompareCall: '{} | null',
        hwFirstFlowCall: '{} | null',
        tariffExplicitlySet: 'boolean',
        hwCatalogTopAdspaceUrl: 'string | null',
        unifiedTrackingEnabled: 'boolean',
        occlDeviceTradeInEnabled: 'boolean',
        deviceTradeInPageValue: 'DeviceTradeInPageValue | null',
        deviceTradeInResultLink: 'Link',
        hwCatalogSelectedTabUrl: 'HwCatalogSelectedTabUrl | null',
        newCatalogEnabled: 'boolean',
        loginStatusTrackingValue: 'LoginStatusTrackingValue | null'
    });

    tabs:HwCatalogTabValue[];
    hardwareCompareCall:PostCall<void>;
    hwFirstFlowCall:Call<any>;
    tariffExplicitlySet:boolean;
    hwCatalogTopAdspaceUrl: string;
    unifiedTrackingEnabled:boolean;
    occlDeviceTradeInEnabled:boolean;
    deviceTradeInPageValue: DeviceTradeInPageValue;
    deviceTradeInResultLink: Link;
    hwCatalogSelectedTabUrl: HwCatalogSelectedTabUrl;
    newCatalogEnabled:boolean;
    loginStatusTrackingValue: LoginStatusTrackingValue;

    selectTab(selectedTab:HwCatalogTabValue) {
        argsContract(arguments, "HwCatalogTabValue");
        _.forEach(this.tabs, (tab:HwCatalogTabValue) => {
            tab.selected = (tab === selectedTab);
        })
    }

    selectSort(selectedSort:SortingValue) {
        argsContract(arguments, "SortingValue");
        _.forEach(this.getCurrentSorts(), (sort:SortingValue) => {
            sort.selected = (sort === selectedSort);
        });
    }

    getCurrentTab():HwCatalogTabValue {
        return _.find(this.tabs, (tab:HwCatalogTabValue) => tab.selected);
    }

    getCurrentCriteriaGroup():CriteriaGroupValue | null {
        const currentTab = this.getCurrentTab();
        return !!currentTab.criteriaGroup ? currentTab.criteriaGroup : null;
    }

    getSelectedSort():SortingValue {
        return _.find(this.getCurrentSorts(), (sort) => sort.selected);
    }

    getCurrentSorts():SortingValue[] {
        return this.getCurrentTab().sortingValues;
    }

    toggleCriteria(selectedCriteria:CriteriaValue) {
        const currentGroup: CriteriaGroupValue | null  = this.getCurrentCriteriaGroup();
        if (currentGroup){
            this.getCurrentCriteriaGroup().criterias
                .filter(criteria => criteria.value == selectedCriteria.value)
                .forEach(criteria => criteria.setSelected(!criteria.selected))
        }
    }

    getSelectedCriteriaList():CriteriaValue[] {
        const currentGroup = this.getCurrentCriteriaGroup();
        return !!currentGroup ? currentGroup.criterias.filter(criteria => criteria.selected) : [];
    }

    getHardware():HardwareValue[] {
        var tab = this.getCurrentTab();
        return tab.getHardwareList();
    }
}
