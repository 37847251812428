import {DeviceTradeInResultType} from "./DeviceTradeInResultType";
import {valueMetaInfo} from "@shop/common";

export class DeviceTradeInResult {

    static META_INFO = valueMetaInfo('hwCatalog:DeviceTradeInResult', {
        id: 'string',
        deviceName: 'string',
        deviceBootable: 'boolean',
        displayDamaged: 'boolean',
        price: 'number',
        caseId: 'string',
        imei: 'string',
        checkTradeInResult: 'DeviceTradeInResultType'
    });

    id: string;
    deviceName: string;
    deviceBootable: boolean;
    displayDamaged: boolean;
    price: number;
    caseId: string;
    imei: string;
    checkTradeInResult: DeviceTradeInResultType;
}