import {DeviceTradeInResult} from '../domain/DeviceTradeInResult';

export class TrackingFormatting {

   public static formatDeviceTradeInTrackingDataElab(deviceTradeInResult: DeviceTradeInResult) : string {
        const priceWithoutDecimal = Math.floor(deviceTradeInResult.price / 100);
        let priceDecimal = deviceTradeInResult.price % 100;
        return deviceTradeInResult.deviceName + " | " +
            priceWithoutDecimal + "," + (priceDecimal < 10 ? "0" + priceDecimal : priceDecimal) + " €";
    }

   public static formatDeviceTradeInTrackingDataEval(price: number) : number {
        let priceWithoutDecimal = Math.floor(price / 100);
        const priceDecimal = price % 100;
        return priceDecimal > 0 ? priceWithoutDecimal++ : priceWithoutDecimal;
    }
}