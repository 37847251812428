import { valueMetaInfo } from '@shop/common';
export class SortingValue {

    static META_INFO = valueMetaInfo('hwCatalog:SortingValue', {
        name: 'string',
        title: 'string',
        selected: 'boolean'
    });

    name:string;
    title:string;
    selected:boolean;

}
