import {IHwCatalogService} from "../../service/IHwCatalogService";
import {HwCatalogService} from "../../service/HwCatalogService";

export class LoadMoreButtonController {

    static $inject = ['hwCatalogService'];

    constructor(public hwCatalogService:IHwCatalogService) {
    }

    $onInit() {
        window.addEventListener('occl.load-more-button.onClick', (event) => {
            this.hwCatalogService.showMoreRows();
            this.hwCatalogService.trackProductImpression("loadMoreButton");
            this.hwCatalogService.updateWindowRegardingPagination(window.location.href);
        })
    }

    public getMaxCountOfItems() {
        return this.hwCatalogService.getManagedState().hardwareListSize;
    }

    public getCountOfItems() {
        return this.hwCatalogService.getPageSize();
    }
    public getParameterName() {
        return HwCatalogService.URL_PARAMETER_NAME_PAGE;
    }

    public getPage() {
        return this.hwCatalogService.getPaginationStep();
    }

}


