import {IOnInit} from 'angular';
import { ServiceState, IHwCatalogService } from '../../service/IHwCatalogService';
import { CriteriaValue } from '../../domain/CriteriaValue';
import {CriteriaGroupValue} from "../../domain/CriteriaGroupValue";
import {FilterItem} from "../../domain/FilterItem";


export class FilterController implements IOnInit {

    // state
    state:ServiceState;

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService:IHwCatalogService) {
        this.state = hwCatalogService.getManagedState();
    }

    $onInit() {
        this.updateNavigationFlag("false");
		!this.trackSelectedItemGet() ? this.trackSelectedItemSet("Alle"): null;

        window.addEventListener('occl.shop-filter.changed', (event) => {
            this.hwCatalogService.setFilterType('category switcher');
            const filterValue: String = event["detail"]["value"];
            const criteriaGroup = this.getCriteriaGroupValue();
            const rememberedCriteriaValue = this.getLastString(filterValue).toString();

            if(rememberedCriteriaValue){
                this.trackSelectedItemSet(rememberedCriteriaValue);
            }

            if (criteriaGroup) {
                const criterias = criteriaGroup.criterias;
                this.unselectAllCriterias(criterias);

                const matchedCriteria = criterias.filter(criteria => criteria.value === filterValue);
                matchedCriteria.length > 0 ? this.activateCriterias(matchedCriteria) : this.unselectAllCriterias(criterias);

                this.updatePage();
            }
            this.hwCatalogService.getHwCatalogTrackingData('');
        });
    }

    getFilterItems = (): FilterItem[] => {
        const criteriaGroup = this.getCriteriaGroupValue();
        let filterItems: FilterItem[] = [];
        const defaultSelectedFilterItem: FilterItem = {
            displayText: "Alle",
            amountAfterFilter: this.getAllHardwaresOfSelectedTab().length,
            value: "All",
            selected: false
        }
        const checkUpdated = JSON.parse(this.getNavigationFlagStatus());

        if (criteriaGroup) {
            const urlParams = new URLSearchParams(window.location.search);
            const criteriaValueFromURL: string | null = urlParams.get('filter');

			let rememberedSelectedCriteria: string | null = this.trackSelectedItemGet();
			rememberedSelectedCriteria === '*' ? rememberedSelectedCriteria = "Sonstige" : rememberedSelectedCriteria;

            const mappedFilterItems = this.mapCriteriasToFilterItems(criteriaGroup.criterias);
            const selectedCriteria: CriteriaValue | undefined = criteriaGroup.criterias
                .filter(criteria => criteria.selected).pop();
            const isSelectedCriteriaInFilterItems = mappedFilterItems
                .filter(item => {
                    return selectedCriteria ? item.value === selectedCriteria.value : false
                }).length > 0;


			if (rememberedSelectedCriteria) {
				let matchedCriteria: FilterItem | undefined =  mappedFilterItems
					.filter(criteria => criteria.displayText === rememberedSelectedCriteria).pop();
				const selectedCriteriaGroup: CriteriaValue | undefined =  criteriaGroup.criterias
					.filter(criteria => criteria.title === rememberedSelectedCriteria).pop();

				//If criteria value is available from URL
				if (criteriaValueFromURL) {
					const matchedCriteriaValueFromURL: FilterItem | undefined = mappedFilterItems
						.filter(criteria => (criteria.displayText.replace(/ /g, "_").toLowerCase()) === criteriaValueFromURL.toLowerCase()).pop();

					if (matchedCriteriaValueFromURL) {
						matchedCriteria = matchedCriteriaValueFromURL;
						matchedCriteria.selected = true;

						this.trackSelectedItemSet(matchedCriteriaValueFromURL.displayText);
					} else {
						this.unselectAllCriterias(criteriaGroup.criterias);
						defaultSelectedFilterItem.selected = true;

						this.trackSelectedItemSet("Alle");
					}
				}
				if (matchedCriteria) {
					this.unselectAllCriterias(criteriaGroup.criterias);
					matchedCriteria.selected = true;
					selectedCriteriaGroup.selected = true;

					if(!checkUpdated){
						this.updateNavigationFlag("true");
						this.updatePage();
					}
				}
				else {
					this.unselectAllCriterias(criteriaGroup.criterias);
					defaultSelectedFilterItem.selected = true;
					this.trackSelectedItemSet("Alle");
					if(!checkUpdated){
						this.updateNavigationFlag("true");
						this.updatePage();
					}
				}
			}
            else if (!selectedCriteria) {
                defaultSelectedFilterItem.selected = true;
                this.unselectAllCriterias(criteriaGroup.criterias);
            } else if (selectedCriteria && !isSelectedCriteriaInFilterItems) {
                defaultSelectedFilterItem.selected = true;
                this.unselectAllCriterias(criteriaGroup.criterias);
                this.updatePage();
            }
            filterItems = [defaultSelectedFilterItem ,...mappedFilterItems];
        }
        return filterItems;
    }

    updatePage = ():void => {
        this.hwCatalogService.updatePage();
    };

    updateNavigationFlag = (value:string):void => {
        this.hwCatalogService.setNavigationPageUpdateFlag(value);
    };

    getNavigationFlagStatus = ():string => {
        return this.hwCatalogService.getNavigationPageUpdateFlag();
    };

    removeActiveFilter = (criteria:CriteriaValue):void => {
        criteria.toggle();
        this.updatePage();
    };

    isTariffExplicitlySet = ():boolean => {
        return this.hwCatalogService.isTariffExplicitlySet();
    };


    private getLastString = (criteria:String): String => criteria.substring(criteria.lastIndexOf('/') + 1);

    private trackSelectedItemSet = (status:string): void => sessionStorage.setItem("selectedItem", status);

    private trackSelectedItemGet = (): string => sessionStorage.getItem("selectedItem");

    private getCriteriaGroupValue = (): CriteriaGroupValue | null => this.state.criteriaGroup;

    private unselectAllCriterias = (criterias: CriteriaValue[]): void => {
        criterias.forEach(criteria => {
            criteria.setSelected(false);
        });
    }

    private activateCriterias = (criterias: CriteriaValue[]): void => {
        criterias.forEach(criteria => {
            criteria.setSelected(true);
        })
    }

    private mapCriteriasToFilterItems = (criterias: CriteriaValue[]): FilterItem[] => {
        const hwList = this.getAllHardwaresOfSelectedTab();
        return criterias.map(criteria => {
            const filteredHwList = hwList.filter(hw => hw.criteriaValues.indexOf(criteria.value) > -1);

            return {
                displayText: criteria.title,
                amountAfterFilter: filteredHwList.length,
                value: criteria.value,
                selected: criteria.selected
            };
        }).filter(filterItem => filterItem.amountAfterFilter > 0);
    }

    private getAllHardwaresOfSelectedTab = () => {
        const selectedTab = this.state.tabs.filter(tab => tab.selected).pop();
        return selectedTab.getHardwareList();
    };
}
