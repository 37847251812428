import {valueMetaInfo} from '@shop/common';

export class BundleValue {

    static META_INFO = valueMetaInfo('hwCatalog:BundleValue', {
        tariffName: 'string',
        tariffOfferName: 'string | null',
        tariffDataVolume: 'string | null'
    });

    tariffName:string;
    tariffOfferName:string;
    tariffDataVolume:string;

}
    
