import {IHwCatalogService} from "../../service/IHwCatalogService";

export class HardwareTileController {

    static $inject = ['hwCatalogService'];

    constructor(public hwCatalogService:IHwCatalogService) {

    }

    isNewCatalogEnabled():boolean {
        return this.hwCatalogService.isNewCatalogEnabled();
    }

    nativeLazyLoadingAvailable(): boolean {
        return 'loading' in document.createElement('img');
    }

    intersectionObserverAvailable(): boolean {
        return 'IntersectionObserver' in window;
    }

    public priceReplace(value: string): string{
        return value.toString().replace(/[.]/gi, ",");
    }
    public activationpricevisiblity(activationFee: number, promotionActivation: number): string{
        if(activationFee === 0){
            return '';
        }else if (promotionActivation === null){
            return ',{"label":"Anschlusspreis","promotion":"","value":"'+this.priceReplace(activationFee.toFixed(2))+'","subtype":"Extra","chargevisibility":"BUNDLE_RECURRING"}';
        }
        else {
            return '';
        }
    }

    public marketingText(value: string): string{
        return "<span style='font-size: 13.5px;color: #5f6264;'>"+value+"</span>";
    }

    public replaceQuoteFromString(value: string): string{
        return value.replace(/"/g, "''");
    }

    observeImages() {
        const images = [].slice.call(document.querySelectorAll('img.co-hardware-tile__image'));

        if (this.intersectionObserverAvailable()) {
            const lazyImageObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target as HTMLImageElement;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            images.forEach(lazyImage => {
                lazyImageObserver.observe(lazyImage);
            });
        }
    }
    trackProductClick(offerName : string): void  {
        const allHwProducts = this.hwCatalogService.getManagedState().hardwareRowList.reduce((acc, row) => acc.concat(row), []);
        const clickedProduct = allHwProducts.filter(hw => hw.offerName === offerName);
        this.hwCatalogService.trackProductClick(clickedProduct[0].ecommerceProductValue, allHwProducts.indexOf(clickedProduct[0])+1);
    }
    public getSchemaData(data: any) {
        let rawData = {
            "@context":"https://schema.org/",
            "@type":"Product",
            "name":data.description,
            "image":data.imageUrl,
            "brand":data.ecommerceProductValue.attributes.dimension165,
            "offers":{"@type":"Offer","priceCurrency":"EUR","price":data.price.totalPrice},
            "url":data.detailWwwAbsoluteCall.constantPayload.link.uri}
        let finalRawData = JSON.stringify(rawData);
        return "<script type='application/ld+json'>" + finalRawData + "</script>";
    }
}
