import { enumMetaInfo } from '@shop/common';
export class PriceTemplateType {

    static META_INFO = enumMetaInfo('hwCatalog:PriceTemplateType');

    private static _values:{ [s:string]:PriceTemplateType; } = {};

    static forName(obj):PriceTemplateType {
        if (obj === null) {
            return null;
        }
        return PriceTemplateType._values[obj];
    }

    static values():PriceTemplateType[] {
        return _.values(PriceTemplateType._values);
    }


    static BUNDLE_RECURRING = new PriceTemplateType("BUNDLE_RECURRING");
    static DEVICE_RECURRING = new PriceTemplateType("DEVICE_RECURRING");
    static DEVICE_ONE_TIME = new PriceTemplateType("DEVICE_ONE_TIME");

    constructor(public  name:string) {
        argsContract(arguments, 'str');
        PriceTemplateType._values[name] = this;
    }

}
    
