import {IHwCatalogService, ServiceState} from "../../service/IHwCatalogService";
import {DeviceTradeInResult} from "../../domain/DeviceTradeInResult";
import {HwCatalogTrackingService} from '../../tracking/HwCatalogTrackingService';
import {TrackingFormatting} from '../../utilitys/TrackingFormatting';

export class OcclDeviceTradeInController {

    state: ServiceState;
    static $inject = ['hwCatalogService', 'hwCatalogTrackingService'];

  constructor(public hwCatalogService:IHwCatalogService, private hwCatalogTrackingService: HwCatalogTrackingService) {
      const remove: string = "remove";
      const edit: string = "edit";
      const info: string = "info";
      const warningMessage: string = "Leider kann das Angebot momentan nicht erstellt werden. Bitte versuche es später erneut.";
      const buttonLabel: string = "Preis erfahren";

    this.state = hwCatalogService.getManagedState();
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.ready", (ev: CustomEvent) => {
          let hardwareName = "";
          if (!!this.state.deviceTradeInResult) {
              hardwareName = this.state.deviceTradeInResult.deviceName
          }
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInLoaded(hardwareName);
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.deleteTradeIn", (ev: CustomEvent) => {
          const deviceTradeInResult =  this.getDeviceTradeInResult();
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInModuleButtonClick(
              TrackingFormatting.formatDeviceTradeInTrackingDataElab(deviceTradeInResult),
              TrackingFormatting.formatDeviceTradeInTrackingDataEval(deviceTradeInResult.price),
              remove)

          this.handleDeleteEvent();

      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.editTradeIn", (ev: CustomEvent) => {
          const deviceTradeInResult =  this.getDeviceTradeInResult();
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInModuleButtonClick(
              TrackingFormatting.formatDeviceTradeInTrackingDataElab(deviceTradeInResult),
              TrackingFormatting.formatDeviceTradeInTrackingDataEval(deviceTradeInResult.price),
              edit)
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.startTradeIn", (ev: CustomEvent) => {
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInStarted(buttonLabel);
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.toggleOffer", (ev: CustomEvent) => {
          const deviceTradeInResult = this.getDeviceTradeInResult();
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInDropDownClick(
              TrackingFormatting.formatDeviceTradeInTrackingDataElab(deviceTradeInResult),
              TrackingFormatting.formatDeviceTradeInTrackingDataEval(deviceTradeInResult.price)
          );
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.infoLink", (ev: CustomEvent) => {
          const deviceTradeInResult =  this.getDeviceTradeInResult();
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInModuleButtonClick(
              TrackingFormatting.formatDeviceTradeInTrackingDataElab(deviceTradeInResult),
              TrackingFormatting.formatDeviceTradeInTrackingDataEval(deviceTradeInResult.price),
              info)
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.scs.onError", (ev: CustomEvent) => {
          this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInWarning(warningMessage);
      });
      document.getElementById("occlDeviceTradeIn").addEventListener("occl.app-device-trade.scs.onSuccess", (ev: CustomEvent) => {
          this.handleSuccessEvent(ev.detail);
      });
  }

    private handleSuccessEvent(internalId: string) {
        this.hwCatalogService.updateDeviceTradeInResult(internalId);
    }

    public getDeviceTradeInResult(): DeviceTradeInResult{
        if(!!this.state.deviceTradeInResult){
            return this.state.deviceTradeInResult;
        }
        return undefined;
    }

    private handleDeleteEvent() {
        this.hwCatalogService.deleteDeviceTradeInResult();
    }

  public getDeviceTradeInExternalId(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().externalID : "";
  }

  public getDeviceTradeInAuthToken(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().deviceTradeInToken : "";
  }

  public getDeviceTradeInChannel(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().tradeInChannel.name : "";
  }

  public getDeviceTradeInCmsUrl(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().cmsUrl : "";
  }

  public getDeviceTradeInCssUrl(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().cssUrl : "";
  }

  public getDeviceTradeInBaseUrl(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.getDeviceTradeInPageValue().baseUrl : "";
  }

  public isOcclDeviceTradeInEnabled(): string {
      return !!this.hwCatalogService.getDeviceTradeInPageValue() ? this.hwCatalogService.isOcclDeviceTradeInEnabled() : "";
  }
}