import { enumMetaInfo } from '@shop/common';
export class LogicType {

    static META_INFO = enumMetaInfo('hwCatalog:LogicType');

    private static _values:{ [s:string]:LogicType; } = {};

    static forName(obj):LogicType {
        argsContract(arguments, 'string | null');
        if (obj === null) {
            return null;
        }
        return LogicType._values[obj];
    }

    static values():LogicType[] {
        return _.values(LogicType._values);
    }


    static AND = new LogicType("AND");
    static OR = new LogicType("OR");

    constructor(public  name:string) {
        argsContract(arguments, 'str');
        LogicType._values[name] = this;
    }

}
