import { IHwCatalogService, ServiceState, HwCompareState } from './IHwCatalogService';
import {
    IPageValueProvider, ILogger, ILoggerService, IPostUrlService,
    IRelativeLocationService, ICallService, IRenderModeService, ITrackingService, PostCall, Call
} from '@shop/common';
import { HwCatalogPageValue } from '../domain/HwCatalogPageValue';
import { HwCatalogTabValue } from '../domain/HwCatalogTabValue';
import { HardwareValue } from '../domain/HardwareValue';
import { MarketingClassificationType } from '../domain/MarketingClassificationType';
import { CriteriaGroupValue } from '../domain/CriteriaGroupValue';
import { SortingValue } from '../domain/SortingValue';
import { LogicType } from '../domain/LogicType';
import { HwCatalogValue } from '../domain/HwCatalogValue';
import { CriteriaValue } from '../domain/CriteriaValue';
import {HwOnlyOrBundleState} from "../domain/HwOnlyOrBundleState";
import {TargetGroupSwitcherValue} from "../domain/TargetGroupSwitcherValue";
import * as _ from "underscore";
import {ActionTargetState} from "../domain/ActionTargetState";
import {HwCatalogTrackingService} from "../tracking/HwCatalogTrackingService";
import {DeviceTradeInPageValue} from "../domain/DeviceTradeInPageValue";
import {DeviceTradeInResult} from "../domain/DeviceTradeInResult";
import {TrackingFormatting} from '../utilitys/TrackingFormatting';
import {EcommerceProductValue} from "../domain/EcommerceProductValue";

export class HwCatalogService implements IHwCatalogService {

    public static URL_PARAMETER_NAME_PAGE = "p";
    private static REGEX_URL_PARAMETER_PAGE = new RegExp("([?;&])"+ HwCatalogService.URL_PARAMETER_NAME_PAGE +"[^&;]*[;&]?");
    private static REGEX_TITLE_PAGE = new RegExp(" - Seite \\d\\d?");
    static $inject = ['pageValueProvider', '$q', 'loggerService', 'postUrlService', '$sce', 'relativeLocation', 'callService', 'renderModeService', 'trackingService', 'hwCatalogTrackingService', '$location'];

    private static OLD_PAGE_SIZE:number = 6;////changes
    private static NEW_PAGE_SIZE:number = 1;
    private rowsOnTab:{ [key:string]:number} = {};
    private logger:ILogger;

    /** Objects bound to UI **/
    private state:ServiceState;
    private compareState:HwCompareState;
    private newCatalogEnabled: boolean;
    private alreadyTrackedProducts: HardwareValue[] = [];

    //This local state is needed because in current implementation pageValue is not updated if
    // the deviceTradeInResult is changed
    private localDeviceTradeInResult: DeviceTradeInResult;

    constructor(private pageValueProvider:IPageValueProvider<HwCatalogPageValue>,
                private $q:ng.IQService,
                loggerService:ILoggerService,
                private postUrlService:IPostUrlService,
                private $sce:ng.ISCEService,
                private relativeLocation:IRelativeLocationService,
                private callService:ICallService,
                private renderModeService:IRenderModeService,
                private trackingService:ITrackingService,
                private hwCatalogTrackingService: HwCatalogTrackingService) {
        this.logger = loggerService.create('commerce.hwcatalog.HwCatalogService');
        var pageValue = pageValueProvider.getPageValue();
        this.newCatalogEnabled = pageValue.newCatalogEnabled;
        this.state = new ServiceState();

        this.compareState = new HwCompareState(pageValue.hardwareCompareCall);

        // load initial page
        _.forEach(pageValue.tabs, (tab:HwCatalogTabValue) => {
            tab.hwCatalogSwitcherStateValue = pageValue.getCurrentTab().hwCatalogValue.hwCatalogSwitcherStateValue.copy();
            /**
             * while rendering a Snapshot, show all rows-
             */
            this.rowsOnTab[tab.tabName] = renderModeService.isPrerender() ? Number.MAX_VALUE : this.newCatalogEnabled ? HwCatalogService.NEW_PAGE_SIZE : HwCatalogService.OLD_PAGE_SIZE;
        });
        //On initial load deviceTradeInResult is read from pageValue and managed locally afterwards
        this.localDeviceTradeInResult = !!pageValue.deviceTradeInPageValue ? pageValue.deviceTradeInPageValue.deviceTradeInResult : null;
        this.recalculateState(pageValue);
    window.addEventListener('scroll', ()=>{
        if(this.state.hardwareRowList.length <= 2){
            var scrollToElement = document.querySelector('.co-load-more-button') as HTMLElement;
            var hT = scrollToElement.offsetTop;
            var hH = scrollToElement.offsetHeight;
            var wH = window.innerHeight;
            var wS = window.pageYOffset;
            if (wS > (hT + hH - wH)) {
                var page:HwCatalogPageValue = this.pageValueProvider.getPageValue();
                var name = page.getCurrentTab().tabName;
                this.rowsOnTab[name] += this.newCatalogEnabled ? HwCatalogService.NEW_PAGE_SIZE : HwCatalogService.OLD_PAGE_SIZE;
                this.recalculateState(page);
                console.log("this.state.hardwareRowList.length: ", this.state.hardwareRowList.length)
            }
        }else {
            HwCatalogService.NEW_PAGE_SIZE = 2
        }
    })
    }


    $onInit() {
        this.handlePaginationForPageInit();
        this.getHwCatalogTrackingDataOnInitialLoad();
    }

    handlePaginationForPageInit() {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.has(HwCatalogService.URL_PARAMETER_NAME_PAGE)) {
            const page = Number(urlParams.get(HwCatalogService.URL_PARAMETER_NAME_PAGE));
            for (let _i = 1; _i < page; _i++) {
                this.showMoreRows();
            }
            this.updateWindowRegardingPagination(window.location.href);
            if(page > 1) {
                setTimeout(this.scrollToCurrentPage.bind(this));
            }
        }
    }


    recalculateRows(hardwareList:HardwareValue[], maxRowCount:number) {
        var i;
        var columnCount = 0;
        var hardwareRow:HardwareValue[] = [];
        var hardwareRowList:HardwareValue[][] = [];
        var totalColumnCount = this.newCatalogEnabled ? window.innerWidth >= 767 ? 8:4 : 4;
        for (i = 0; i < hardwareList.length; i += 1) {
            var hardware = hardwareList[i];

            hardwareRow.push(hardware);

            if (hardware.marketingClassification === MarketingClassificationType.HERO_DEVICE) {
                columnCount += 2;
            } else {
                columnCount += 1;
            }
            if (columnCount >= totalColumnCount) {
                hardwareRowList.push(hardwareRow);
                hardwareRow = [];
                columnCount = 0;
            }
        }
        if (hardwareRow.length > 0) {
            hardwareRowList.push(hardwareRow);
        }
        this.state.rawHardwareRowList = hardwareRowList;
        this.state.hardwareRowList = hardwareRowList.slice(0, Math.min(hardwareRowList.length, maxRowCount));
        this.state.moreHardwareToShow = hardwareRowList.length !== this.state.hardwareRowList.length;
    }

    getManagedState():ServiceState {
        return this.state;
    }

    recalculateState(pageValue:HwCatalogPageValue) {
        this.state.tabs = pageValue.tabs;
        this.state.criteriaGroup = pageValue.getCurrentCriteriaGroup();
        this.state.sorts = pageValue.getCurrentSorts();
        this.state.selectedCriteriaList = pageValue.getSelectedCriteriaList();
        var maxRowCount = this.rowsOnTab[pageValue.getCurrentTab().tabName];
        var hardwareList = this.filterHardwareList(pageValue.getHardware(), pageValue.getCurrentCriteriaGroup());
        this.state.hardwareListSize = hardwareList.length > 0 ? hardwareList.length.toString() : "Keine";
        var sortedHardwareList = this.sortHardwareList(hardwareList, pageValue.getSelectedSort());
        this.recalculateRows(sortedHardwareList, maxRowCount);
        this.recalculateCompareState(sortedHardwareList);
        this.state.salesHighlight = pageValue.getCurrentTab().salesHighlight;
        if (pageValue.getCurrentTab().hwCatalogValue) {
            this.trackingService.track(pageValue.getCurrentTab().hwCatalogValue.trackingInfoCall);
        }
        this.state.deviceTradeInResult = this.localDeviceTradeInResult;
    }

    recalculateCompareState(hardwareValues:HardwareValue[]) {
        var selectedHardwareDeviceForComparisonList:any[] = _.filter(
            hardwareValues,
            (hw:HardwareValue)=>hw.isSelectedForDeviceCompare()
        );
        this.compareState.selectedHardwareDeviceForComparisonIsEmpty = _.isEmpty(selectedHardwareDeviceForComparisonList);
        this.compareState.selectedHardwareDeviceForComparison = selectedHardwareDeviceForComparisonList;
    }

    sortHardwareList(hardwareList:HardwareValue[], sort:SortingValue) {
        if (sort != null && !_.isUndefined(sort) && sort.name != null && !_.isUndefined(sort.name)) {
            return _.sortBy(hardwareList, (hardware) => hardware.indexBySortingName[sort.name]);
        }
        return hardwareList;
    }

    filterHardwareList(hardwareList:HardwareValue[], criteriaGroups: CriteriaGroupValue | null):HardwareValue[] {
        return _.filter(hardwareList, (hw:HardwareValue) => {
            let selectedValues: string[];
            selectedValues = !!criteriaGroups ? criteriaGroups.getSelectedValues() : [];

            if (selectedValues.length === 0) {
                return true;
            }

            if (criteriaGroups.logicType === LogicType.OR) {
                // one or more criteria is matching
                return _.intersection(selectedValues, hw.criteriaValues).length !== 0;
            }

            if (criteriaGroups.logicType === LogicType.AND) {
                // all criteria values must match
                return _.difference(selectedValues, hw.criteriaValues).length === 0;
            }
        });
    }

    //asynchron
    updatePageAfter(fn:(p:HwCatalogPageValue) => any):ng.IPromise<HwCatalogPageValue> {
        var page:HwCatalogPageValue = this.pageValueProvider.getPageValue();

        // reset the hardware row list... so if the operation take a while a user interface reaction is shown.
        this.state.hardwareRowList = null;
        return this.$q.when(fn(page)).then(
            () => {
                this.recalculateState(page);
                this.getHwCatalogTrackingData('');
                const call = page.getCurrentTab().pageWwwCall;
                if(call && call['constantPayload'] && call['constantPayload']['link']) {
                    this.updateWindowRegardingPagination(call['constantPayload'].link.uri);
                }
                return page;
            }
        )
    }

    private selectTargetStateAction(tab:HwCatalogTabValue, hwOnlyOrBundleState:HwOnlyOrBundleState, targetGroup:string) {
        this.updatePageAfter(
            (page: HwCatalogPageValue) => {
                let currentTab = page.getCurrentTab();
                if (tab) {
                    page.selectTab(tab);
                    currentTab = page.getCurrentTab();
                }

                if (!hwOnlyOrBundleState) {
                    hwOnlyOrBundleState = currentTab.hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue.hwOnlyOrBundleState;
                }
                if (!targetGroup) {
                    targetGroup = currentTab.hwCatalogSwitcherStateValue.selectedTargetGroupSwitcher;
                }

                let actionTargetState = currentTab.catalogRestCalls
                    .filter(s => s.hwOnlyOrBundleState == hwOnlyOrBundleState && s.targetGroup === targetGroup)
                    .pop();

                this.state.lastActionTargetState = actionTargetState;

                /*
                set the switcher state now, not later, to make sure we avoid the impression of 2 target groups selected
                at the same time until we have fetched the data
                 */
                this.updateSwitcherState(page, currentTab, hwOnlyOrBundleState, targetGroup);

                if (actionTargetState.cachedHwCatalogValue) {
                    this.updateCatalogValue(currentTab, actionTargetState);
                } else {
                    // now make the call if we need it
                    return this.loadHwCatalog(actionTargetState.call).then(
                        (catalogValue: HwCatalogValue) => {
                            if (actionTargetState === this.state.lastActionTargetState) {
                                actionTargetState.cachedHwCatalogValue = catalogValue;
                                this.updateCatalogValue(currentTab, actionTargetState);
                            }
                        }
                    );
                }
            }
        );
    }

    private updateCatalogValue(currentTab: HwCatalogTabValue, actionTargetState: ActionTargetState): void {
        currentTab.setCatalogValue(actionTargetState.cachedHwCatalogValue);
        currentTab.hwCatalogSwitcherStateValue = actionTargetState.cachedHwCatalogValue.hwCatalogSwitcherStateValue.copy();
    }

    private updateSwitcherState(page: HwCatalogPageValue, currentTab: HwCatalogTabValue, hwOnlyOrBundleState:HwOnlyOrBundleState, targetGroup:string):void{
        // switcher state should be separate per tab!
        currentTab.hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue.hwOnlyOrBundleState = hwOnlyOrBundleState;
        // target group state should be consistent across tabs!
        page.tabs.forEach(tab => {
            tab.hwCatalogSwitcherStateValue.selectedTargetGroupSwitcher = targetGroup;
        });
    }

    setNavigationPageUpdateFlag(value:string):void {
        sessionStorage.setItem("alreadyUpdated",value);
    };

    getNavigationPageUpdateFlag():string {
       return  sessionStorage.getItem("alreadyUpdated");
    };


    selectHwOnlyMode(): void {
        this.selectTargetStateAction(null, HwOnlyOrBundleState.HW_ONLY, null);

        // Checking current window location is bundle hardware catalog url, in that case user want to
        // see devices with hardware only selection, then it re-direct on hardware only hw catalog page.
        if (null != this.getBundleHwCatalogUrl()) {
            let originUrlForBundle = this.getBundleHwCatalogUrl().substring(0, this.getBundleHwCatalogUrl().indexOf("/e-shop/"));
            let pathNameUrlForBundle = this.getBundleHwCatalogUrl().substring(this.getBundleHwCatalogUrl().indexOf("/e-shop/"), this.getBundleHwCatalogUrl().length);
            if (window.location.origin === originUrlForBundle && window.location.pathname === pathNameUrlForBundle) {
                window.location.replace(this.getHardwareOnlyHwCatalogUrl());
            }
        }
    }

    selectBundleMode(): void {
        this.selectTargetStateAction(null, HwOnlyOrBundleState.BUNDLE, null);

        // Checking current window location is hw only hardware catalog url, in that case user want
        // to see devices with bundle selection, then it re-direct on bundle hw catalog page.
        if (null != this.getHardwareOnlyHwCatalogUrl()) {
            let originUrlForHwOnly = this.getHardwareOnlyHwCatalogUrl().substring(0, this.getHardwareOnlyHwCatalogUrl().indexOf("/e-shop/"));
            let pathNameUrlForHwOnly = this.getHardwareOnlyHwCatalogUrl().substring(this.getHardwareOnlyHwCatalogUrl().indexOf("/e-shop/"), this.getHardwareOnlyHwCatalogUrl().length);
            if (window.location.origin === originUrlForHwOnly && window.location.pathname === pathNameUrlForHwOnly) {
                window.location.replace(this.getBundleHwCatalogUrl());
            }
        }
    }

    selectTargetGroup(newTargetGroupId: string): void {
        this.selectTargetStateAction(null, null, newTargetGroupId);
    }

    getCurrentHwOnlyOrBundleSwitcherState():HwOnlyOrBundleState {
        return this.getPageValue().getCurrentTab().hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue.hwOnlyOrBundleState;
    }

    updatePage() {
        this.logger.debug("updatePage()");
        this.recalculateState(this.pageValueProvider.getPageValue());
    }

    selectSort(sort:SortingValue) {
        var page:HwCatalogPageValue = this.pageValueProvider.getPageValue();
        page.selectSort(sort);
        this.recalculateState(page);
        this.getHwCatalogTrackingData('');
    }

    showMoreRows() {
        this.logger.debug("showMoreRows()");
        if (this.state.moreHardwareToShow) {
            var page:HwCatalogPageValue = this.pageValueProvider.getPageValue();
            var name = page.getCurrentTab().tabName;
            this.rowsOnTab[name] += this.newCatalogEnabled ? HwCatalogService.NEW_PAGE_SIZE : HwCatalogService.OLD_PAGE_SIZE;
            this.recalculateState(page);
        }
    }

    selectDeviceForCompare(hardware:HardwareValue) {
        var page:HwCatalogPageValue = this.pageValueProvider.getPageValue();
        var allHardwareValuesOnPage:HardwareValue[] = page.getHardware();
        var selectedHardwareValues:HardwareValue[] = [];

        for (var i = 0; i < allHardwareValuesOnPage.length; i += 1) {
            if (allHardwareValuesOnPage[i].isSelectedForDeviceCompare()) {
                selectedHardwareValues.push(allHardwareValuesOnPage[i]);
            }
        }

        if (selectedHardwareValues.length > 2) {
            var unselectedHardwareValues:HardwareValue[] = _.difference(allHardwareValuesOnPage, selectedHardwareValues);
            _.each(unselectedHardwareValues, (hw:HardwareValue)=>hw.setEnabledForDeviceCompare(false));
        }
        else {
            _.each(allHardwareValuesOnPage, (hw:HardwareValue)=>hw.setEnabledForDeviceCompare(true));
        }
        this.recalculateCompareState(allHardwareValuesOnPage);
    }

    getCompareState():HwCompareState {
        return this.compareState;
    }

    executeHwFirstFlowCall() {
        this.callService.handleCall(this.getPageValue().hwFirstFlowCall);
    }

    executeTabRedirection(tab:HwCatalogTabValue) {
        this.callService.handleCall(tab.pageWwwCall);
    }

    postComparisonData(postUrlLink:PostCall<void>) {
        var formData:{[key:string]:string} = {};

        for (var i = 0; i < this.compareState.selectedHardwareDeviceForComparison.length; i += 1) {
            if (!_.isEmpty(this.compareState.selectedHardwareDeviceForComparison[i].externalId)) {
                var index = i + 1;
                formData['hardware_id_' + index] = this.compareState.selectedHardwareDeviceForComparison[i].externalId;
                formData['hardware_url_' + index] = this.$sce.getTrustedUrl(this.$sce.trustAsUrl(this.compareState.selectedHardwareDeviceForComparison[i].detailWwwAbsoluteCall.link.uri));
            }
        }

        var url = this.$sce.getTrustedUrl(this.$sce.trustAsUrl(postUrlLink.link.uri));
        this.postUrlService.buildFormAndPostToURL(formData, url);
    }

    getSelectedCriteria():CriteriaValue[] {
        return this.pageValueProvider.getPageValue().getSelectedCriteriaList();
    }

    loadHwCatalog(restCall:Call<HwCatalogValue>):ng.IPromise<HwCatalogValue> {
        return this.callService.handleCall(restCall)
            .then((promiseValue: HwCatalogValue) => promiseValue);
    }

    isTariffExplicitlySet():boolean {
        return this.getPageValue().tariffExplicitlySet;
    }

    getHwCatalogTopAdspaceUrl():string {
        return this.getPageValue().hwCatalogTopAdspaceUrl;
    }

    getHeaderContent(): string {
        return this.getPageValue().getCurrentTab().headerContent;
    }

    getFooterContent(): string {
        return this.getPageValue().getCurrentTab().footerContent;
    }

    private getPageValue():HwCatalogPageValue {
        return this.pageValueProvider.getPageValue()
    }

    getShowSwitcher():boolean {
        return this.getPageValue().getCurrentTab().hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue.showSwitcher;
    }

    getTargetGroupSwitcherValue():TargetGroupSwitcherValue{
        return this.getPageValue().getCurrentTab().targetGroupSwitcherValue;
    }

    getSelectedTargetGroupName():string {
        return this.getPageValue().getCurrentTab().hwCatalogSwitcherStateValue.selectedTargetGroupSwitcher;
    }

    updateWindowRegardingPagination(newLocation:string): void {
        const paginationStep = this.getPaginationStep();
        const location = this.appendFilterQueryParam(
            window.location.href,
            this.calculateLocation(newLocation, paginationStep)
        );

        this.relativeLocation.pushState(location);
        this.updateCanonicalLink(location);
        this.updatePageTitle(paginationStep);
    }

    appendFilterQueryParam(originalLocation:string, newLocation: string): string {
        let basePath = originalLocation;
        const filterIndex = basePath.indexOf('filter');

        if (filterIndex === -1) {
            return newLocation;
        } else {
            const isMoreQueryParamIndex = basePath.indexOf('&', filterIndex);
            let filterParam: string = '';
            if (isMoreQueryParamIndex > -1) {
                filterParam = basePath.slice(filterIndex, isMoreQueryParamIndex);
            } else {
                filterParam = basePath.slice(filterIndex, basePath.length);
            }
            if (newLocation.indexOf('?') > -1) {
                return newLocation + '&' + filterParam;
            } else {
                return newLocation + '?' + filterParam;
            }
        }
    }

    calculateLocation(newLocation: string, paginationStep: number): string {
        newLocation = newLocation.replace(HwCatalogService.REGEX_URL_PARAMETER_PAGE, '');
        if(paginationStep > 1) {
            newLocation = newLocation + (newLocation.indexOf('?') > 0 ? "&" : "?") + HwCatalogService.URL_PARAMETER_NAME_PAGE + "=" + paginationStep;
        }
        return newLocation;
    }

    updateCanonicalLink(newLocation: string) {
        if(document.querySelector('link[rel="canonical"]')) {
            document.querySelector('link[rel="canonical"]').setAttribute('href', newLocation);
        }
    }

    updatePageTitle(paginationStep: number) {
        const cleanTitle = document.title.replace(HwCatalogService.REGEX_TITLE_PAGE, '');
        document.title = cleanTitle + ((paginationStep > 1) ? ' - Seite ' + paginationStep : '');
    }

    scrollToCurrentPage():void {
        const step = this.getPaginationStep();
        let tileRow;
        if(this.newCatalogEnabled){
            tileRow = document.getElementsByClassName("grid-container")[(step-1) * HwCatalogService.NEW_PAGE_SIZE];
        }else{
            tileRow = document.getElementsByClassName("hardware-scroll")[(step-1) * HwCatalogService.OLD_PAGE_SIZE];
        }
        tileRow.scrollIntoView({behavior: 'smooth'});
    }

    getPageSize(): number {
        return this.newCatalogEnabled ? HwCatalogService.NEW_PAGE_SIZE * 12 : HwCatalogService.OLD_PAGE_SIZE * 4;
    }

    getPaginationStep(): number {
        if(!this.state.hardwareRowList) {
            return 1;
        }
        let amountOfItems = this.state.hardwareRowList.reduce((acc, val) => acc.concat(val), []).length;  //aktuell Anzahl an Elementen
        let paginationStep = 0;
        while (amountOfItems > 0) {
            amountOfItems = amountOfItems - (this.newCatalogEnabled ? HwCatalogService.NEW_PAGE_SIZE * 12 : HwCatalogService.OLD_PAGE_SIZE * 4);
            paginationStep = paginationStep + 1;
        }
        return paginationStep;
    }

    getTabs(): HwCatalogTabValue[] {
        return this.getPageValue().tabs;
    }

    getCurrentTabValue(): string {
        return this.getPageValue().getCurrentTab().title;
    }

    getCurrentHwCatalogSwitcherValue(): string {
        //get current Hw Catalog state value
        let hwCatalogSwitcherStateValue = this.getPageValue().getCurrentTab().hwCatalogSwitcherStateValue;

        if(hwCatalogSwitcherStateValue && hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue) {
            return hwCatalogSwitcherStateValue.hwOnlyOrBundleSwitcherValue.hwOnlyOrBundleState.name;
        }

        return null;
    }

    getCurrentCriteriaValue(): string {
        let currentSelectedCriteria  = this.getSelectedCriteria();
        if(currentSelectedCriteria.length === 1 && currentSelectedCriteria[0].selected){
           return currentSelectedCriteria[0].name;
        }
        else {
            return 'Alle';
        }
    }

    getCurrentTargetGroupValue(): string {
        //get current Hw Catalog state value
        let hwCatalogSwitcherStateValue = this.getPageValue().getCurrentTab().hwCatalogSwitcherStateValue;

        if(hwCatalogSwitcherStateValue) {
            return this.getSelectedTargetGroupName();
        }

        return null;
    }

    getCurrentSortValue(): string {
        if(this.getPageValue().getCurrentTab().sortingValues){
            let currentSort = this.getPageValue().getCurrentTab().sortingValues.filter(currentSort => currentSort.selected === true);
            return currentSort[0].name;
        }
    }

    isFilterParamsInURL(): boolean{
        const urlParams = new URLSearchParams(window.location.search);
        const filterParamsInURL: boolean = urlParams.has('filter');
        return filterParamsInURL;
    }

    getHardwareListSize(): string {
        return this.state.hardwareListSize;
    }

    getHwCatalogTrackingData(tabValue: string): void {
        this.state.trackingCount+=this.hwCatalogTrackingService.trackHwCatalogSelectionOnChange(
            tabValue != '' ? tabValue : this.getCurrentTabValue(),
            this.getCurrentHwCatalogSwitcherValue(),
            this.getCurrentCriteriaValue(),
            this.getHardwareListSize(),
            this.getCurrentTargetGroupValue(),
            this.getCurrentSortValue(),
            this.isFilterParamsInURL(),
            this.state.trackingCount,
            this.state.filterType,
            this.state.prevStateValue);
        this.trackProductImpression("filter");

    }

    getHwCatalogTrackingDataOnInitialLoad() {
        this.state.trackingCount = 1;
        this.hwCatalogTrackingService.trackHwCatalogSelectionOnInitialLoad(this.getCurrentTabValue(), this.getCurrentHwCatalogSwitcherValue(), this.getCurrentCriteriaValue(), this.getHardwareListSize(), this.getCurrentTargetGroupValue(), this.getCurrentSortValue(), this.isFilterParamsInURL());
    }

    getDeviceTradeInPageValue(): DeviceTradeInPageValue {
        return this.getPageValue().deviceTradeInPageValue;
    }

    public isOcclDeviceTradeInEnabled(): boolean {
        return this.getPageValue().occlDeviceTradeInEnabled;
    }

    public deleteDeviceTradeInResult(): void {
        let deviceTradeinResultCall = new PostCall(this.getPageValue().deviceTradeInPageValue.deviceTradeInDeleteResultLink, 0);
        this.callService.handleCall(deviceTradeinResultCall).then(() => {
            this.localDeviceTradeInResult = null;
            this.state.deviceTradeInResult = null;
            this.updatePage();
        });
    }

    public updateDeviceTradeInResult(internalId: string) {
        let deviceTradeinResultCall = new PostCall(this.getPageValue().deviceTradeInResultLink, internalId);
        this.callService.handleCall(deviceTradeinResultCall).then((deviceTradeInResult: DeviceTradeInResult) => {
            this.localDeviceTradeInResult = deviceTradeInResult;
            this.state.deviceTradeInResult = deviceTradeInResult;

            if (!!this.state.deviceTradeInResult) {
                if (this.state.deviceTradeInResult.deviceName && this.state.deviceTradeInResult.price) {
                    const elabValue = this.state.deviceTradeInResult.deviceName + ' | ' + this.state.deviceTradeInResult.price;
                    this.hwCatalogTrackingService.buildTrackingObjectForDeviceTradeInSuccessful(
                        TrackingFormatting.formatDeviceTradeInTrackingDataEval(this.state.deviceTradeInResult.price),
                        elabValue)
                }
            }
        });
    }

    getBundleHwCatalogUrl(): string {
        if(null != this.getPageValue().hwCatalogSelectedTabUrl){
            return this.getPageValue().hwCatalogSelectedTabUrl.bundleHwCatalogUrl;
        }
        return null;
    }

    getHardwareOnlyHwCatalogUrl(): string {
        if (null != this.getPageValue().hwCatalogSelectedTabUrl) {
            return this.getPageValue().hwCatalogSelectedTabUrl.hardwareOnlyHwCatalogUrl;
        }
        return null;
    }

    isNewCatalogEnabled(): boolean{
        return this.getPageValue().newCatalogEnabled;
    }

    setFilterType(filterType: string) {
        this.state.filterType = filterType;
        this.state.prevStateValue = `${this.getCurrentTabValue()}/${this.getCurrentHwCatalogSwitcherValue()}/${this.getCurrentCriteriaValue()}_${this.getHardwareListSize()}/${this.getCurrentTargetGroupValue()}/${this.getCurrentSortValue()}/${!this.isFilterParamsInURL() ? 'no_url_params' : this.getCurrentCriteriaValue()}`;
    }
    trackProductImpression(componentName:string) {
        const currentStateValue = `${this.getCurrentTabValue()}/${this.getCurrentHwCatalogSwitcherValue()}/${this.getCurrentCriteriaValue()}_${this.getHardwareListSize()}/${this.getCurrentTargetGroupValue()}/${this.getCurrentSortValue()}/${!this.isFilterParamsInURL() ? 'no_url_params' : this.getCurrentCriteriaValue()}`;
        const allHwProducts = this.state.hardwareRowList.reduce((acc, row) => acc.concat(row), []);
        let visitedHwProducts;
        if(componentName == "filter" || componentName == "loadMoreButton"){
            if(componentName == "filter"){
                this.alreadyTrackedProducts = [];
            }
            const productDifferenceCount = allHwProducts.length-this.alreadyTrackedProducts.length;
            const trackedProductCount = this.alreadyTrackedProducts.length;
            if(productDifferenceCount > 4){
                    visitedHwProducts = window.innerWidth < 768 ? allHwProducts.slice(trackedProductCount,trackedProductCount+2) : allHwProducts.slice(trackedProductCount,trackedProductCount+4);
            }else{
                visitedHwProducts = allHwProducts.slice(trackedProductCount, allHwProducts.length);
            }
        }else{
            const childrenDivIds = document.querySelectorAll("#hw-tiles-container div[id]");
            const visitedDivIds = [];

            for (var i = 0; i < childrenDivIds.length; i++) {
                var divId = childrenDivIds[i].id;
                var divTop = childrenDivIds[i].getBoundingClientRect().top;
                var divBottom = childrenDivIds[i].getBoundingClientRect().bottom;

                if (divTop < window.innerHeight && divBottom >= 0) {
                    visitedDivIds.push(divId);
                }
            }
            visitedHwProducts = allHwProducts.filter(hw => visitedDivIds.some(divId => divId === hw.offerName));
        }
        const trackingHwProducts = visitedHwProducts.filter(hw => !this.alreadyTrackedProducts.some(trackedHw => trackedHw.offerName === hw.offerName));
        if (trackingHwProducts.length >= 6) {
            // Push tracking data to window.tefDataLayer
            this.hwCatalogTrackingService.buildTrackingObjectForProductImpression(currentStateValue,trackingHwProducts.slice(0, 6)
                .filter((hw) => hw.ecommerceProductValue != null)
                .map((hw) => hw.ecommerceProductValue), this.alreadyTrackedProducts.length);
            this.alreadyTrackedProducts.push(...trackingHwProducts.slice(0, 6));
        } else if (trackingHwProducts.length > 0) {
            // Push tracking data to window.tefDataLayer
            this.hwCatalogTrackingService.buildTrackingObjectForProductImpression(currentStateValue,trackingHwProducts
                .filter((hw) => hw.ecommerceProductValue != null)
                .map((hw) => hw.ecommerceProductValue), this.alreadyTrackedProducts.length);
            this.alreadyTrackedProducts.push(...trackingHwProducts);
        }
    }
    trackProductClick(ecommerceProductValue: EcommerceProductValue, hwIndex: number) {
        const currentStateValue = `${this.getCurrentTabValue()}/${this.getCurrentHwCatalogSwitcherValue()}/${this.getCurrentCriteriaValue()}_${this.getHardwareListSize()}/${this.getCurrentTargetGroupValue()}/${this.getCurrentSortValue()}/${!this.isFilterParamsInURL() ? 'no_url_params' : this.getCurrentCriteriaValue()}`;
        this.hwCatalogTrackingService.trackProductClick(ecommerceProductValue, currentStateValue, hwIndex);
    }
}
