import { valueMetaInfo, Call, NavigationEvent } from '@shop/common';
import { HwCatalogValue } from './HwCatalogValue';
import { CriteriaGroupValue } from './CriteriaGroupValue';
import { SortingValue } from './SortingValue';
import { HardwareValue } from './HardwareValue';
import {TargetGroupSwitcherValue} from "./TargetGroupSwitcherValue";
import {ActionTargetState} from "./ActionTargetState";
import {HwCatalogSwitcherStateValue} from "./HwCatalogSwitcherStateValue";
export class HwCatalogTabValue {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogTabValue', {
        tabName: 'string',
        title: 'string',
        headerContent: 'string',
        footerContent: 'string',
        glyphiconIconValue: 'string',
        salesHighlight: 'boolean',
        selected: 'boolean',

        hwCatalogValue: 'HwCatalogValue | null',
        criteriaGroup: 'CriteriaGroupValue | null',
        sortingValues: '[SortingValue]',

        pageWwwCall: '{} | null',

        targetGroupSwitcherValue: 'TargetGroupSwitcherValue',

        catalogRestCalls: '[ActionTargetState]'
    });

    tabName:string;
    title:string;
    headerContent: string;
    footerContent: string;
    glyphiconIconValue:string;
    salesHighlight:boolean;
    selected:boolean;

    hwCatalogValue:HwCatalogValue;
    criteriaGroup:CriteriaGroupValue | null;
    sortingValues:SortingValue[];

    pageWwwCall:Call<NavigationEvent>;

    targetGroupSwitcherValue:TargetGroupSwitcherValue;

    /*
    We only need this state because we do not want to manipulate the data we get from the server and still have to
    switch states directly when the user clicks for a fluent UI experience.
     */
    hwCatalogSwitcherStateValue:HwCatalogSwitcherStateValue = null;

    catalogRestCalls: ActionTargetState[];

    setCatalogValue(hwCatalogValue:HwCatalogValue) {
        this.hwCatalogValue = hwCatalogValue;
    }

    getHardwareList():HardwareValue[] {
        return this.hwCatalogValue.hardware;
    }

    isHardwareLoaded():boolean {
        return this.hwCatalogValue !== null;
    }
}
