import {IHwCatalogService} from "../../service/IHwCatalogService";
import {HwOnlyOrBundleState} from "../../domain/HwOnlyOrBundleState";

export class HwOnlyBundleSwitcherController {

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService:IHwCatalogService) {

    }

    $onInit() {
        let element = document.querySelector('tef-shop-toggle-switcher')
        if(element){
            element.addEventListener('occl.shop-toggle-switcher.changed', ($event: any) => {
                this.hwCatalogService.setFilterType('hardware');
                if($event.detail.selected){
                    this.hwCatalogService.selectHwOnlyMode();
                } else {
                    this.hwCatalogService.selectBundleMode();
                }
            });
        }
    }

    selectHwOnly = ($event):void => {
        $event.preventDefault();
        this.hwCatalogService.selectHwOnlyMode();
    };

    selectBundle = ($event):void => {
        $event.preventDefault();
        this.hwCatalogService.selectBundleMode();
    };

    isHwOnlySelected = ():boolean => {
        let switcherState:HwOnlyOrBundleState = this.hwCatalogService.getCurrentHwOnlyOrBundleSwitcherState();
        return switcherState == HwOnlyOrBundleState.HW_ONLY;
    };


    showSwitcher = ():boolean => {
        return this.hwCatalogService.getShowSwitcher();
    }

}
