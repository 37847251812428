import {IHwCatalogService} from '../../service/IHwCatalogService';

export class NavigationController {

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService'];
    selectedIndex: number;
    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService:IHwCatalogService) {
    this.preSelectedTab();
    }

    $onInit() {
        window.addEventListener('occl.shop-tabs.open', (event) => {
            this.hwCatalogService.setFilterType('tab');
            const tabTitle = (<CustomEvent>event).detail.activeItem;
            this.hwCatalogService.getTabs().forEach((tabs, index) => {
                if (tabs.title === tabTitle) {
                    if (!tabs.selected) {
                        this.hwCatalogService.getHwCatalogTrackingData(tabs.title);
                        this.hwCatalogService.executeTabRedirection(tabs);
                    }
                }
            })
        });
    }

    preSelectedTab = ():void => {
        if(this.hwCatalogService.getTabs()) {
            this.hwCatalogService.getTabs().forEach((tabs, index) => {
                if (tabs.selected === true) {
                    this.selectedIndex = index+1;
                }
            })
        }
    }
}