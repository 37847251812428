import {valueMetaInfo} from '@shop/common';
export class TargetGroupValue {

    static META_INFO = valueMetaInfo('hwCatalog:TargetGroupValue', {
        id: 'string',
        label: 'string',
        tooltip: 'string',
    });

    id:string;
    label:string;
    tooltip:string;
}
