import {HwCatalogService} from './service/HwCatalogService';
import {MainController} from './controller/MainController';
import {FilterController} from './directive/filter/FilterController';
import {SortControlController} from './directive/sortControl/coSortControlController';
import {HardwareTilesContainerController} from './directive/hardwareTilesContainer/coHardwareTilesContainerController';
import {NavigationController} from './directive/navigation/coNavigationController';
import {coHardwareTile} from './directive/hardwareTile/coHardwareTile';
import {coPriceLabel} from './directive/priceLabel/coPriceLabel';
import {coNavigation} from './directive/navigation/coNavigation';
import coPriceBoxBundleRecurringTemplateHtml from './directive/priceBox/coPriceBoxBundleRecurringTemplate.html';
import coPriceBoxDeviceRecurringTemplateHtml from './directive/priceBox/coPriceBoxDeviceRecurringTemplate.html';
import coPriceBoxDeviceOneTimeTemplateHtml from './directive/priceBox/coPriceBoxDeviceOneTimeTemplate.html';
import {createPriceBoxDirective} from './directive/priceBox/createPriceBoxDirective';
import {coFilter} from './directive/filter/coFilter';
import {coSortControl} from './directive/sortControl/coSortControl';
import {coHardwareTilesContainer} from './directive/hardwareTilesContainer/coHardwareTilesContainer';
import {classToFactory, EventService, IExternalService, IMappingServiceProvider} from '@shop/common';
import {BundleValue} from './domain/BundleValue';
import {CriteriaGroupValue} from './domain/CriteriaGroupValue';
import {CriteriaValue} from './domain/CriteriaValue';
import {HwCatalogSelectedTabUrl} from './domain/HwCatalogSelectedTabUrl';
import {HwCatalogPageValue} from './domain/HwCatalogPageValue';
import {HwCatalogTabValue} from './domain/HwCatalogTabValue';
import {HwCatalogValue} from './domain/HwCatalogValue';
import {HardwareValue} from './domain/HardwareValue';
import {PriceTemplateType} from './domain/PriceTemplateType';
import {LogicType} from './domain/LogicType';
import {MarketingClassificationType} from './domain/MarketingClassificationType';
import {PriceValue} from './domain/PriceValue';
import {SortingValue} from './domain/SortingValue';
import {HwCatalogSelectedConfigurationValue} from './domain/HwCatalogSelectedConfigurationValue';
import {HwOnlyOrBundleSwitcherValue} from "./domain/HwOnlyOrBundleSwitcherValue";
import {HwOnlyOrBundleState} from "./domain/HwOnlyOrBundleState";
import {HwOnlyBundleSwitcherController} from "./directive/hwOnlyBundleSwitcher/coHwOnlyBundleSwitcherController";
import {coHwOnlyBundleSwitcher} from "./directive/hwOnlyBundleSwitcher/coHwOnlyBundleSwitcher";
import {coAdspaceTopContainer} from "./directive/adspaceTopContainer/coAdspaceTopContainer";
import {AdspaceTopContainerController} from "./directive/adspaceTopContainer/coAdpsaceTopContainerController";
import {TargetGroupValue} from "./domain/TargetGroupValue";
import {TargetGroupSwitcherValue} from "./domain/TargetGroupSwitcherValue";
import {coTargetGroupSwitcher} from "./directive/targetGroupSwitcher/coTargetGroupSwitcher";
import {TargetGroupSwitcherController} from "./directive/targetGroupSwitcher/coTargetGroupSwitcherController";
import {ActionTargetState} from "./domain/ActionTargetState";
import {HwCatalogSwitcherStateValue} from "./domain/HwCatalogSwitcherStateValue";
import {HardwareTileController} from "./directive/hardwareTile/HardwareTileController";
import {LoadMoreButtonController} from "./directive/loadMoreButton/coLoadMoreButtonController";
import {coLoadMoreButton} from "./directive/loadMoreButton/coLoadMoreButton";
import {HwCatalogTrackingService} from "./tracking/HwCatalogTrackingService";
import {EventListenerRegistry, Events} from "./tracking/EventListenerRegistry";
import angular = require('angular');
import { coOcclDeviceTradeIn } from './directive/occlDeviceTradeIn/coOcclDeviceTradeIn';
import { OcclDeviceTradeInController } from './directive/occlDeviceTradeIn/coOcclDeviceTradeInController';
import {DeviceTradeInPageValue} from "./domain/DeviceTradeInPageValue";
import {DeviceTradeInChannel} from "./domain/DeviceTradeInChannel";
import {DeviceTradeInResultType} from "./domain/DeviceTradeInResultType";
import {DeviceTradeInResult} from "./domain/DeviceTradeInResult";
import {EcommerceProductValue} from "./domain/EcommerceProductValue";
import {LoginStatusTrackingValue} from "./domain/LoginStatusTrackingValue";

// this type is used to describe a constructor that construct a 'any'
// this is necessary because to be a valid IController at least one optional property of controller must be present.
type AnyConstructor = { new(...args: any[]): any };

angular.module('commerce_hwcatalog', ['commerce_common'])

// service level dependencies
    .factory('hwCatalogService', classToFactory(HwCatalogService))

    //dont move this below the run()-configurations.
    .controller('MainController', <AnyConstructor>MainController)

    // markup level dependencies
    .controller('FilterController', <AnyConstructor>FilterController)
    .controller('OcclDeviceTradeInController', <AnyConstructor>OcclDeviceTradeInController)
    .controller('SortControlController', <AnyConstructor>SortControlController)
    .controller('HardwareTilesContainerController', <AnyConstructor>HardwareTilesContainerController)
    .controller('NavigationController', <AnyConstructor>NavigationController)
    .controller('HwOnlyBundleSwitcherController', <AnyConstructor>HwOnlyBundleSwitcherController)
    .controller('AdspaceTopContainerController', <AnyConstructor>AdspaceTopContainerController)
    .controller('HardwareTileController', <AnyConstructor>HardwareTileController)
    .controller('TargetGroupSwitcherController', <AnyConstructor>TargetGroupSwitcherController)
    .controller('LoadMoreButtonController', <AnyConstructor>LoadMoreButtonController)

    .factory('eventListenerRegistry', classToFactory(EventListenerRegistry))
    .service('hwCatalogTrackingService', classToFactory(HwCatalogTrackingService))

    .run(['eventListenerRegistry', angular.noop])
    .run(['eventService', (eventService: EventService) => {
        eventService.emit(Events.HW_CATALOG_INITIALIZED);
    }])

    .directive("coOcclDeviceTradeIn", coOcclDeviceTradeIn)
    .directive('coHardwareTile', coHardwareTile)
    .directive('coPriceLabel', coPriceLabel)
    .directive('coNavigation', coNavigation)
    .directive('coHwOnlyBundleSwitcher', coHwOnlyBundleSwitcher)
    .directive('coPriceBoxBundleRecurring', createPriceBoxDirective('coPriceBoxBundleRecurring', coPriceBoxBundleRecurringTemplateHtml))
    .directive('coPriceBoxDeviceRecurring', createPriceBoxDirective('coPriceBoxDeviceRecurring', coPriceBoxDeviceRecurringTemplateHtml))
    .directive('coPriceBoxDeviceOneTime', createPriceBoxDirective('coPriceBoxDeviceOneTime', coPriceBoxDeviceOneTimeTemplateHtml))
    .directive("coFilter", coFilter)
    .directive("coSortControl", coSortControl)
    .directive("coHardwareTilesContainer", coHardwareTilesContainer)
    .directive("coAdspaceTopContainer", coAdspaceTopContainer)
    .directive("coTargetGroupSwitcher", coTargetGroupSwitcher)
    .directive("coLoadMoreButton", coLoadMoreButton)
    .config(['mappingServiceProvider', function (mappingServiceProvider: IMappingServiceProvider) {
        mappingServiceProvider.addAnnotatedType(BundleValue);
        mappingServiceProvider.addAnnotatedType(CriteriaGroupValue);
        mappingServiceProvider.addAnnotatedType(CriteriaValue);
        mappingServiceProvider.addAnnotatedType(HwCatalogSelectedTabUrl);
        mappingServiceProvider.addAnnotatedType(HwCatalogPageValue);
        mappingServiceProvider.addAnnotatedType(HwCatalogTabValue);
        mappingServiceProvider.addAnnotatedType(LoginStatusTrackingValue);
        mappingServiceProvider.addAnnotatedType(HwCatalogValue);
        mappingServiceProvider.addAnnotatedType(HardwareValue);
        mappingServiceProvider.addAnnotatedType(PriceTemplateType);
        mappingServiceProvider.addAnnotatedType(LogicType);
        mappingServiceProvider.addAnnotatedType(MarketingClassificationType);
        mappingServiceProvider.addAnnotatedType(EcommerceProductValue);
        mappingServiceProvider.addAnnotatedType(PriceValue);
        mappingServiceProvider.addAnnotatedType(SortingValue);
        mappingServiceProvider.addAnnotatedType(HwCatalogSelectedConfigurationValue);
        mappingServiceProvider.addAnnotatedType(HwOnlyOrBundleSwitcherValue);
        mappingServiceProvider.addAnnotatedType(HwOnlyOrBundleState);
        mappingServiceProvider.addAnnotatedType(TargetGroupValue);
        mappingServiceProvider.addAnnotatedType(TargetGroupSwitcherValue);
        mappingServiceProvider.addAnnotatedType(ActionTargetState);
        mappingServiceProvider.addAnnotatedType(HwCatalogSwitcherStateValue);
        mappingServiceProvider.addAnnotatedType(DeviceTradeInPageValue);
        mappingServiceProvider.addAnnotatedType(DeviceTradeInChannel);
        mappingServiceProvider.addAnnotatedType(DeviceTradeInResult);
        mappingServiceProvider.addAnnotatedType(DeviceTradeInResultType);
    }])
    .run(['externalService', (externalService: IExternalService) => {
        externalService.initExternalApps();
    }])
    .config(['$locationProvider', function ($locationProvider: ng.ILocationProvider) {
        $locationProvider.html5Mode(<any>{
            enabled: true,
            requireBase: true,
            rewriteLinks: false
        });
    }]);
