import { enumMetaInfo } from '@shop/common';
export class HwOnlyOrBundleState {

    static META_INFO = enumMetaInfo('hwCatalog:HwOnlyOrBundleState');

    private static _values:{ [s:string]:HwOnlyOrBundleState; } = {};

    static forName(obj):HwOnlyOrBundleState {
        argsContract(arguments, 'string | null');
        if (obj === null) {
            return null;
        }
        return HwOnlyOrBundleState._values[obj];
    }

    static values():HwOnlyOrBundleState[] {
        return _.values(HwOnlyOrBundleState._values);
    }

    static HW_ONLY = new HwOnlyOrBundleState("HW_ONLY");
    static BUNDLE = new HwOnlyOrBundleState("BUNDLE");

    constructor(public  name:string) {
        argsContract(arguments, 'str');
        HwOnlyOrBundleState._values[name] = this;
    }

}
