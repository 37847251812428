import { ServiceState, IHwCatalogService } from '../../service/IHwCatalogService';
import {TargetGroupSwitcherValue} from "../../domain/TargetGroupSwitcherValue";
import {TargetGroupValue} from "../../domain/TargetGroupValue";
export class TargetGroupSwitcherController {

    // state
    state:ServiceState;

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService:IHwCatalogService) {
        this.state = hwCatalogService.getManagedState();
    }

    public getTargetGroupSwitcherValue():TargetGroupSwitcherValue {
        return this.hwCatalogService.getTargetGroupSwitcherValue();
    }

    public isSelected(targetGroup:TargetGroupValue):boolean {
        return this.hwCatalogService.getSelectedTargetGroupName() === targetGroup.id;
    }

    public select(targetGroup:TargetGroupValue):void {
        this.hwCatalogService.setFilterType('customer segment');
        let targetGroupToSelect:string = this.isSelected(targetGroup) ? this.getTargetGroupSwitcherValue().defaultTargetGroup.id : targetGroup.id;
        this.hwCatalogService.selectTargetGroup(targetGroupToSelect);
    }
}

