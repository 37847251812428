
import { HardwareValue } from '../domain/HardwareValue';
import { HwCatalogTabValue } from '../domain/HwCatalogTabValue';
import { CriteriaGroupValue } from '../domain/CriteriaGroupValue';
import { SortingValue } from '../domain/SortingValue';
import { CriteriaValue } from '../domain/CriteriaValue';
import { PriceTemplateType } from '../domain/PriceTemplateType';
import { PostCall, Url } from '@shop/common';
import {TargetGroupSwitcherValue} from "../domain/TargetGroupSwitcherValue";
import {ActionTargetState} from "../domain/ActionTargetState";
import {DeviceTradeInPageValue} from "../domain/DeviceTradeInPageValue";
import {DeviceTradeInResult} from '../domain/DeviceTradeInResult';
import {EcommerceProductValue} from "../domain/EcommerceProductValue";
export class ServiceState {
    rawHardwareRowList:HardwareValue[][] = [];
    hardwareRowList:HardwareValue[][] = [];
    moreHardwareToShow:boolean = false;
    tabs:HwCatalogTabValue[] = [];
    criteriaGroup:CriteriaGroupValue;
    sorts:SortingValue[] = [];
    title:string;
    selectedCriteriaList:CriteriaValue[];
    hardwareListSize:string;
    salesHighlight:boolean;
    priceTemplateType:PriceTemplateType = PriceTemplateType.BUNDLE_RECURRING;
    lastActionTargetState:ActionTargetState;
    deviceTradeInResult: DeviceTradeInResult;
    trackingCount: number;
    filterType: string;
    prevStateValue: string;
}

export class HwCompareState {
    open:boolean = false;
    selectedHardwareDeviceForComparison:any[];
    selectedHardwareDeviceForComparisonIsEmpty:boolean = true;
    postUrlLink:PostCall<void>;

    constructor(postUrlLink:PostCall<void>) {
        this.postUrlLink = postUrlLink;
    }
}

export interface IHwCatalogService {
    getManagedState():ServiceState
    getCompareState():HwCompareState
    selectHwOnlyMode()
    selectBundleMode()
    getCurrentHwOnlyOrBundleSwitcherState()
    updatePage()
    selectSort(sort:SortingValue)
    showMoreRows()
    selectDeviceForCompare(hardwareValue:HardwareValue):void
    postComparisonData(postUrlLink:Url):void
    getSelectedCriteria():CriteriaValue[]
    isTariffExplicitlySet():boolean
    executeHwFirstFlowCall()
    getHwCatalogTopAdspaceUrl():string
    getHeaderContent(): string
    getFooterContent(): string
    getShowSwitcher():boolean
    getTargetGroupSwitcherValue():TargetGroupSwitcherValue
    getSelectedTargetGroupName(): string
    selectTargetGroup(newTargetGroupId: string): void
    updateWindowRegardingPagination(newLocation:string): void
    getPaginationStep(): number
    getPageSize(): number
    setNavigationPageUpdateFlag(value:string)
    getNavigationPageUpdateFlag():string
    getTabs(): HwCatalogTabValue[]
    getHwCatalogTrackingData(tabValue: string)
    isOcclDeviceTradeInEnabled()
    getDeviceTradeInPageValue(): DeviceTradeInPageValue
    updateDeviceTradeInResult(internalId: string): void
    getHwCatalogTrackingDataOnInitialLoad()
    deleteDeviceTradeInResult(): void;
    getBundleHwCatalogUrl(): string;
    getHardwareOnlyHwCatalogUrl(): string;
    isNewCatalogEnabled(): boolean;
    executeTabRedirection(tab:HwCatalogTabValue)
    setFilterType(filterType: string);
    trackProductImpression(componentName: string): void;
    trackProductClick(ecommerceProductValue: EcommerceProductValue, hwIndex: number): void;
}
    
