import { ServiceState, IHwCatalogService } from '../service/IHwCatalogService';
import { ISnapshotService } from '@shop/common';
export class MainController {

    // state
    state:ServiceState;

    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['snapshotService', 'hwCatalogService'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param snapshotService
     * @param hwCatalogService
     */
    constructor(public snapshotService:ISnapshotService,
                public hwCatalogService:IHwCatalogService) {

        this.state = hwCatalogService.getManagedState();
        snapshotService.snapshot();
    }

    /**
     * ATTENTION : don´t you ever write methods like this:
     * showMoreRows = function() {
         *  this.hwCatalogService.showMoreRows();
         * }
     *
     * Always use the typescript-variant:
     * showMoreRows = () => {
         *  this.hwCatalogService.showMoreRows();
         * }
     *
     * It keeps your 'this'.
     *
     * This will silently fail for the "Controller as" - Syntax, as
     * for controller as, 'this' will be the scope on runtime, not the controller.
     */
    showMoreRows = () => {
        this.hwCatalogService.showMoreRows();
    };

    getState = ():ServiceState => {
        return this.state;
    };

    getHeaderContent(): string {
        return this.hwCatalogService.getHeaderContent();
    }

    getFooterContent(): string {
        return this.hwCatalogService.getFooterContent();
    }
}
