export class PriceBoxController {

    static $inject = ['$scope'];

    constructor(private $scope: ng.IScope) {
    }

    public getTariffDescription(): string {
        if (this.$scope['hardware'].fox36Active) {
            return this.$scope['tariffExplicitlySet'] ? ' ' + this.$scope['hardware'].bundle.tariffDataVolume :  this.$scope['hardware'].bundle.tariffDataVolume;
        } else {
            return this.$scope['tariffExplicitlySet'] ? ' ' + this.$scope['hardware'].bundle.tariffName : 'z. B. ' + this.$scope['hardware'].bundle.tariffName;
        }
    }

    get activationFeeText() {
        let activationFee = this.$scope['hardware'].price.activationFee;

        let activationFeeFormatted = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(activationFee);
        return '+ Anschlusspreis ' + activationFeeFormatted;

    }

    public hasActivationFee() {
        let activationFee = this.$scope['hardware'].price.activationFee;
        return activationFee && activationFee > 0;

    }

    public getJoinedMonthlyPrices(): string {
        if (!this.$scope['hardware'].fox36Active) {
            return this.$scope['hardware'].price ? this.$scope['hardware'].price.nextMonthlyPrices.join('<br/>') : "";
        }
    }
}
