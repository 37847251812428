import {ConstantCall, IEventService, IPageValueProvider, ITrackingService, TrackingInfoValue} from "@shop/common";
import {HwCatalogPageValue} from "../domain/HwCatalogPageValue";
import {Events} from "./EventListenerRegistry";
import {EcommerceProductValue} from "../domain/EcommerceProductValue";

export class HwCatalogTrackingService {

    static $inject: string[] = ['trackingService', 'pageValueProvider', 'eventService'];

    private readonly isUnifiedTrackingEnabled: boolean;

    constructor(private readonly trackingService: ITrackingService,
                private readonly pageValueProvider: IPageValueProvider<HwCatalogPageValue>,
                private readonly eventService: IEventService) {
        this.isUnifiedTrackingEnabled = this.pageValueProvider.getPageValue().unifiedTrackingEnabled;
    }

    $onInit() {
        this.eventService.subscribe(Events.HW_CATALOG_INITIALIZED, () => {
            this.trackPage();
        })
    }

    public trackPage() {
        const data = this.isUnifiedTrackingEnabled ?
            {
                event : "init",
                page : {
                    content : undefined,
                    category : "hardware-catalog",
                    productCategory : undefined,
                    application : "e-shop",
                    template: "whitelabel-hardware-catalog-dev"
                }
            }
            :
            {
                pageCategory : "whitelabel-catalog-dev",
                page : {}
            };

        this.track(data)
    }

    private track(data: any) {
        this.trackingService.track(new ConstantCall<TrackingInfoValue>({rawContent: JSON.stringify(data)}))
    }

    public trackHwCatalogSelectionOnInitialLoad(currentTab, stateValue, selectedCriteriaValue, hardwareListSize, targetGroupValue, sortValue, isFilterParamsInUrl): void {
            const data =
                {
                    event: "eShopFilter_view",
                    event_name: "filter",
                    eCat: "filter",
                    eAct: `display filter - ${isFilterParamsInUrl ? 'url' : 'default'}`,
                    eLab: `${currentTab}/${stateValue}/${selectedCriteriaValue}_${hardwareListSize}/${targetGroupValue}/${sortValue}/${!isFilterParamsInUrl ? 'no_url_params': selectedCriteriaValue}`,
                    eVal: 0,
                    nonInteraction: true
                };
            this.track(data);
    }

    public trackHwCatalogSelectionOnChange(currentTab, stateValue, selectedCriteriaValue, hardwareListSize, targetGroupValue, sortValue, isFilterParamsInUrl, attempt, filterType, prevStateValue): number {
        const currentStateValue = `${currentTab}/${stateValue}/${selectedCriteriaValue}_${hardwareListSize}/${targetGroupValue}/${sortValue}/${!isFilterParamsInUrl ? 'no_url_params' : selectedCriteriaValue}`;
        if(currentStateValue != prevStateValue){
        const data =
                {
                    event: `eShopFilter_click_${attempt}`,
                    event_name: "filter",
                    eCat: "filter",
                    eAct: `click filter - ${filterType}`,
                    eLab: `${currentStateValue}`,
                    previousEventState: `${prevStateValue}`,
                    eVal: 0,
                    nonInteraction: false
                };
            this.track(data);
            return 1;
        }
        return 0;
    }

    public buildTrackingObjectForDeviceTradeInLoaded(hardwareName: string) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_view',
            eCat: 'Device Trade In',
            eAct: 'view – device trade in module available',
            eLab: hardwareName.length == 0 ? 'hardware not available' : hardwareName,
            eVal: 0,
            nonInteraction: true
        };
        this.track(data);

    }

    public buildTrackingObjectForDeviceTradeInStarted(buttonLabel: string) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_click_cta',
            eCat: 'Device Trade In',
            eAct: 'click – start device trade in',
            eLab: buttonLabel,
            eVal: 0,
            nonInteraction: false
        };
        this.track(data);

    }

    public buildTrackingObjectForDeviceTradeInSuccessful(evalValue: number , elabValue: string) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_view',
            eCat: 'Device Trade In',
            eAct: 'view – end device trade',
            eLab: elabValue,
            eVal: evalValue,
            nonInteraction: true
        };
        this.track(data);
    }

    public buildTrackingObjectForDeviceTradeInModuleButtonClick(elabValue: string, evalValue: number, eActValue: string) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_click_cta',
            eCat: 'Device Trade In',
            eAct: "click - " + eActValue,
            eLab: elabValue,
            eVal: evalValue,
            nonInteraction: false
        };
        this.track(data);

    }

    public buildTrackingObjectForDeviceTradeInDropDownClick(elabValue: string, evalValue: number) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_click_cta',
            eCat: 'Device Trade In',
            eAct: 'click – dropdown arrow',
            eLab: elabValue,
            eVal: evalValue,
            nonInteraction: false
        };
        this.track(data);

    }

    public buildTrackingObjectForDeviceTradeInWarning(warningMessage: string) {
        const data =
            {
            event: 'deviceTradeIn',
            event_name: 'device_trade_in_view',
            eCat: 'Device Trade In',
            eAct: 'view – warning',
            eLab: warningMessage,
            eVal: 0,
            nonInteraction: true
        };
        this.track(data);
    }
    public buildTrackingObjectForProductImpression(currentStateValue: string, ecommerceProductValues: EcommerceProductValue[], hwIndex: number) {
        const map = ecommerceProductValues.map((ecommerceProductValue, index) => ({
            name: ecommerceProductValue.name,
            quantity: ecommerceProductValue.quantity,
            list: currentStateValue,
            ...ecommerceProductValue.attributes,
            position: (hwIndex + index + 1).toString(),
        }));

        const data =
            {
                event: 'productImpression',
                eCat: 'EEC',
                eAct: 'productImpression',
                eLab: '',
                nonInteraction: true,
                eshop_login_status: { ...this.pageValueProvider.getPageValue().loginStatusTrackingValue},
                ecommerce: {
                    impressions: [...map]
                }
            };
        this.track(data);
    }
    public trackProductClick(ecommerceProductValue: EcommerceProductValue, currentStateValue: string, hwIndex: number) {
        const data = {
            event: 'productClick',
            eCat: 'EEC',
            eAct: 'productClick',
            eLab: '',
            nonInteraction: false,
            eshop_login_status: { ...this.pageValueProvider.getPageValue().loginStatusTrackingValue },
            ecommerce: {
                click: {
                    actionField: {'list': currentStateValue},
                    products: [{
                        name: ecommerceProductValue.name,
                        quantity: ecommerceProductValue.quantity,
                        ...ecommerceProductValue.attributes,
                        position: hwIndex.toString()
                    }]
                }
            },
        };
        this.track(data)
    }
}