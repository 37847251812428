import {valueMetaInfo} from '@shop/common';

export class HwCatalogSelectedTabUrl {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogSelectedTabUrl', {
        bundleHwCatalogUrl: 'string',
        hardwareOnlyHwCatalogUrl: 'string'
    });

    bundleHwCatalogUrl: string;
    hardwareOnlyHwCatalogUrl: string;
}