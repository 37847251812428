import {valueMetaInfo} from "@shop/common";

export class EcommerceProductValue {

    static META_INFO = valueMetaInfo('hwCatalog:EcommerceProductValue', {
        attributes: 'any',
        name: 'string',
        quantity: 'number',
    });

    attributes: any;
    name: string;
    quantity: number;
}