import { valueMetaInfo } from '@shop/common';
export class PriceValue {

    static META_INFO = valueMetaInfo('hwCatalog:PriceValue', {
        oneTimePrice: 'number',
        monthlyPrice: 'number',
        totalPrice: 'number | null',
        activationFee: 'number | null',
        nextMonthlyPrices: 'string[]',
        promotionOneTimePrice: 'number | null',
        promotionMonthlyPrice: 'number | null',
        promotionTotalPrice: 'number | null',
        promotionActivationFee: 'number | null',
        promotionNextMonthlyPrices: 'string[]'

    });

    oneTimePrice:number;
    monthlyPrice:number;
    totalPrice:number;
    activationFee:number;
    nextMonthlyPrices:string[];
    promotionOneTimePrice:number;
    promotionMonthlyPrice:number;
    promotionTotalPrice: number;
    promotionActivationFee:number;
    promotionNextMonthlyPrices:string[];

}
    
