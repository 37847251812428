import {HwOnlyOrBundleState} from "./HwOnlyOrBundleState";
import {Call, valueMetaInfo} from "@shop/common";
import {HwCatalogValue} from "./HwCatalogValue";

export class ActionTargetState {

    static META_INFO = valueMetaInfo('hwCatalog:ActionTargetState', {
        hwOnlyOrBundleState: 'HwOnlyOrBundleState',
        targetGroup: 'string',
        call: '{}'
    });

    hwOnlyOrBundleState: HwOnlyOrBundleState;
    targetGroup: string;
    call: Call<HwCatalogValue>;

    // to be set in the client once loaded
    cachedHwCatalogValue: HwCatalogValue;
}