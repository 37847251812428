import { enumMetaInfo } from "@shop/common";
export class DeviceTradeInChannel {

    static META_INFO = enumMetaInfo('hwCatalog:DeviceTradeInChannel');

    static FACE_TO_FACE = new DeviceTradeInChannel("FACE_TO_FACE");
    static REMOTE = new DeviceTradeInChannel("REMOTE");

    constructor(public name:string) {
        argsContract(arguments, 'str');
    }
}