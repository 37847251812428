import { enumMetaInfo } from "@shop/common";

export class DeviceTradeInResultType {
    static META_INFO = enumMetaInfo('hwCatalog:DeviceTradeInResultType');

    static UNKNOWN = new DeviceTradeInResultType("UNKNOWN");
    static PURCHASE_PORTAL = new DeviceTradeInResultType("PURCHASE_PORTAL");
    static OFFER_PRICE = new DeviceTradeInResultType("OFFER_PRICE");
    static RECYCLING = new DeviceTradeInResultType("RECYCLING");
    static REQUEST_PRICE = new DeviceTradeInResultType("REQUEST_PRICE");
    static ERROR = new DeviceTradeInResultType("ERROR");

    constructor(public name:string) {
        argsContract(arguments, 'str');
    }
}