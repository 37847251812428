import {valueMetaInfo} from "@shop/common";
import {HwOnlyOrBundleState} from "./HwOnlyOrBundleState";

export class HwOnlyOrBundleSwitcherValue {

    static META_INFO = valueMetaInfo('hwCatalog:HwOnlyOrBundleSwitcherValue', {
        hwOnlyOrBundleState: 'HwOnlyOrBundleState',
        showSwitcher: 'boolean'
    });

    hwOnlyOrBundleState: HwOnlyOrBundleState;
    showSwitcher: boolean;

}