import {valueMetaInfo, Html, Url, ConstantCall, NavigationEvent} from '@shop/common';
import {PriceValue} from './PriceValue';
import {MarketingClassificationType} from './MarketingClassificationType';
import {BundleValue} from './BundleValue';
import {PriceTemplateType} from './PriceTemplateType';
import {EcommerceProductValue} from "./EcommerceProductValue";

export class HardwareValue {

    static META_INFO = valueMetaInfo('hwCatalog:HardwareValue', {
        externalId: 'string',
        description: 'Html | string',
        urlName: 'string',
        offerName: 'string',
        price: 'PriceValue',
        marketingClassification: 'MarketingClassificationType',
        colors: '[string]',
        colorImageUrl: '[string]',
        imageUrl: 'Url | string',
        highlights: '{} | string',
        marketingHint: '{} | string',
        bundle: 'BundleValue | null',
        fox36Active: 'boolean',
        criteriaValues: '[string]',
        indexBySortingName: '{*: number}',
        imageUrlSmall: 'Url | string',
        detailWwwAbsoluteCall: '{}',
        stockHint: 'string | null',
        contractDurationHint: 'string | null',
        priceTemplateType: 'PriceTemplateType',
        ratingStars: 'string | null',
        rateDurationText: 'string',
        rateDurationValue: 'string',
        monthlyText: 'string',
        oneTimeHwoText: 'string',
        oneTimeBundleText: 'string',
        fullPriceText: 'string',
        ecommerceProductValue: 'EcommerceProductValue | null',
        promotionActive: 'boolean',
    });

    externalId: string;
    description: Html;
    urlName: string;
    offerName: string;
    price: PriceValue;
    marketingClassification: MarketingClassificationType;
    colors: string[];
    colorImageUrl: string[];
    imageUrl: Url;
    highlights: Html;
    marketingHint: Html;
    bundle: BundleValue;
    fox36Active: boolean;
    criteriaValues: string[];
    indexBySortingName: { [s: string]: number; };
    imageUrlSmall: Url;
    detailWwwAbsoluteCall: ConstantCall<NavigationEvent>;
    stockHint: string;
    contractDurationHint: string;
    priceTemplateType: PriceTemplateType;
    ratingStars: string;
    rateDurationText: string;
    rateDurationValue: string;
    monthlyText: string;
    oneTimeHwoText: string;
    oneTimeBundleText: string;
    fullPriceText: string;
    ecommerceProductValue: EcommerceProductValue;
    promotionActive: 'boolean';

    public selectedForDeviceCompare: boolean;
    public enabledForDeviceCompare: boolean;

    isHero(): boolean {
        return this.marketingClassification == MarketingClassificationType.HERO_DEVICE;
    }

    isSelectedForDeviceCompare(): boolean {
        if (this.selectedForDeviceCompare === undefined) {
            return false; // default value
        }
        return this.selectedForDeviceCompare;
    }

    setSelectedForDeviceCompare(isSelected): void {
        this.selectedForDeviceCompare = isSelected;
    }

    isEnabledForDeviceCompare(): boolean {
        if (this.enabledForDeviceCompare === undefined) {
            return true; // default value
        }
        return this.enabledForDeviceCompare;
    }

    setEnabledForDeviceCompare(isEnabled): void {
        this.enabledForDeviceCompare = isEnabled;
    }

    getRatingStars(): string {
        return this.ratingStars;
    }

    isDeviceOneTime(): boolean {
        return this.priceTemplateType === PriceTemplateType.DEVICE_ONE_TIME;
    }
}
    
