import coPriceLabelTemplateHtml from './coPriceLabelTemplate.html';
import {PriceLabelController} from './coPriceLabelController';
export function coPriceLabel():ng.IDirective {
    return {
        scope: {
            amount: '=coPriceLabelAmount',
            name: '=coPriceLabelName',
            description: '=coPriceLabelDescription',
            textAfter: '=coPriceLabelTextAfter',
            focus: '=coPriceLabelFocus',
            hint: '=coPriceLabelHint',
            text: '=coPriceLabelText',
            value: '=coPriceLabelValue',
        },
        replace: true,
        template: coPriceLabelTemplateHtml,
        controller: <any>PriceLabelController,
        controllerAs: '$ctrl',
        bindToController: true
    };
}
