import coNavigationTemplateHtml from './coNavigationTemplate.html';
export function coNavigation():ng.IDirective {
    return {
        scope: {
            tabs: '=coNavigationTabs',
            selectTab: '=coNavigationSelectTab'
        },
        replace: false,
        template: coNavigationTemplateHtml,
        controller: 'NavigationController',
        controllerAs: 'navigationController'
    };
}
