import { valueMetaInfo } from '@shop/common';
export class CriteriaValue {

    static META_INFO = valueMetaInfo('hwCatalog:CriteriaValue', {
        name: 'string',
        title: 'string',
        value: 'string',
        selected: 'boolean'
    });

    name:string;
    title:string;
    value:string;
    selected:boolean;

    toggle():void {
        this.selected = !this.selected;
    }

    setSelected(newValue: boolean): void {
        this.selected = newValue;
    }
}
