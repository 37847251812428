import {Call, DeleteCall, Link, PostCall, valueMetaInfo} from "@shop/common";
import {DeviceTradeInChannel} from "./DeviceTradeInChannel";
import {DeviceTradeInResult} from "./DeviceTradeInResult";


export class DeviceTradeInPageValue{

    static META_INFO = valueMetaInfo('hwCatalog:DeviceTradeInPageValue', {
        externalID: 'string',
        deviceTradeInResult: 'DeviceTradeInResult | null',
        deviceTradeInToken: 'string',
        tradeInChannel: 'DeviceTradeInChannel',
        cmsUrl: 'string',
        cssUrl: 'string',
        baseUrl: 'string',
        deviceTradeInDeleteResultLink: 'Link'
    });

    externalID: string;
    deviceTradeInResult: DeviceTradeInResult;
    deviceTradeInToken: string;
    tradeInChannel: DeviceTradeInChannel;
    cmsUrl: string;
    cssUrl: string;
    baseUrl: string;
    deviceTradeInDeleteResultLink:Link;

}