import {HwCompareState, IHwCatalogService, ServiceState} from '../../service/IHwCatalogService';
import {TrackingProductLine} from "@shop/common";
import {HardwareValue} from "../../domain/HardwareValue";
import {PriceTemplateType} from "../../domain/PriceTemplateType";
import {HwCatalogTrackingService} from "../../tracking/HwCatalogTrackingService";

export class HardwareTilesContainerController {

    compareState:HwCompareState;
    state:ServiceState;
    /**
     * always keep this injector, it makes your services safe
     * against html minification. Keep it near your constructor, as the
     * array must match the arguments in the constructor.
     * @type {string[]}
     */
    static $inject = ['hwCatalogService', '$window'];

    /**
     * Define your Dependencies in the constructor.
     * Don´t make any assignments - typescript will handle this for you.
     * @param hwCatalogService
     */
    constructor(public hwCatalogService:IHwCatalogService,
                private $window:ng.IWindowService) {
        this.compareState = hwCatalogService.getCompareState();
        this.state = hwCatalogService.getManagedState();

        // Add event listener to window object for load event
        this.$window.addEventListener('load', () => {
            // Call the pushTrackedProductsToDataLayer() function on load
            this.pushTrackedProductsToDataLayer();
        });

        // Add event listener to window object for scroll event
        this.$window.addEventListener('scroll', () => {
            // Call the pushTrackedProductsToDataLayer() function on scroll
            this.pushTrackedProductsToDataLayer();
        });
    }

    isTariffExplicitlySet():boolean {
        return this.hwCatalogService.isTariffExplicitlySet();
    }

    getProductLineFor(hardware: HardwareValue): TrackingProductLine {
        if (hardware.priceTemplateType === PriceTemplateType.BUNDLE_RECURRING) {
            return TrackingProductLine.BUNDLE;
        } else {
            // if it isn't a bundle-tile, it has to be a hardware-only tile
            // for determining the product line, it doesn't matter if the hardware has a recurring or non-recurring price
            return TrackingProductLine.HARDWARE_ONLY
        }
    }

    isNewCatalogEnabled():boolean {
        return this.hwCatalogService.isNewCatalogEnabled();
    }
    private pushTrackedProductsToDataLayer() {
            // Push tracking data to window.tefDataLayer
            this.hwCatalogService.trackProductImpression("hardwareTiles");
    }
    getSchemaData(): any{
        const schemaData:any = [];
        const rawData = this.hwCatalogService.getManagedState().rawHardwareRowList.reduce((acc, row) => acc.concat(row), []);
        let positionCounter = 1;
        rawData.forEach((data) => {
            schemaData.push({
                "@type":"ListItem",
                "position": positionCounter++,
                "item": {
                    "@type": "Product",
                    "name":data.description,
                    "image":data.imageUrl,
                    "brand":{
                        "@type": "Brand",
                        "name": data.ecommerceProductValue.attributes.dimension165
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "EUR",
                        "price": parseFloat(
                            String((
                                    (data.ecommerceProductValue.attributes.metric4 && !isNaN(data.ecommerceProductValue.attributes.metric4))
                                        ? parseFloat(data.ecommerceProductValue.attributes.metric4)
                                        : 0
                                ) +
                                (
                                    (data.ecommerceProductValue.attributes.metric5 && !isNaN(data.ecommerceProductValue.attributes.metric5))
                                        ? parseFloat(data.ecommerceProductValue.attributes.metric5)
                                        : 0
                                ))
                        ).toFixed(2)
                    },
                    "url":data.detailWwwAbsoluteCall.constantPayload.link.uri
                }
            })
        })

        let headObjectData:any = {
            "@context": "https:\/\/schema.org",
            "@type": "ItemList",
            "itemListElement":schemaData
        };
        let finalRawData = JSON.stringify(headObjectData);
        return "<script type='application/ld+json'>" + finalRawData + "</script>";
    }
}
