import { Trackable, valueMetaInfo, Call, TrackingInfoValue } from '@shop/common';
import { HardwareValue } from './HardwareValue';
import {HwCatalogSwitcherStateValue} from "./HwCatalogSwitcherStateValue";
export class HwCatalogValue implements Trackable {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogValue', {
        hardware: '[HardwareValue]',
        beginIndex: 'number',
        resultCount: 'number',
        maxValue: 'number',
        loadNextRestLink: 'string | null',
        trackingInfoCall: 'Call | null',
        hwCatalogSwitcherStateValue: 'HwCatalogSwitcherStateValue'
    });

    hardware:HardwareValue[];
    beginIndex:number;
    resultCount:number;
    maxValue:number;
    loadNextRestLink:string;
    trackingInfoCall:Call<TrackingInfoValue>;
    hwCatalogSwitcherStateValue:HwCatalogSwitcherStateValue;

    isLastPage():boolean {
        return !this.loadNextRestLink;
    }
}
