import { valueMetaInfo } from '@shop/common';
export class HwCatalogSelectedConfigurationValue {

    static META_INFO = valueMetaInfo('hwCatalog:HwCatalogSelectedConfigurationValue', {
        shopContext: 'string',
        targetGroup: 'string',
        tariffOfferName: 'string | null',
        hardwareOfferName: 'string',
        tariffPackNames: '[string] | null'
    });

    shopContext:string;
    targetGroup:string;
    tariffOfferName:string;
    hardwareOfferName:string;
    tariffPackNames:string[];
}
    
