import coHardwareTileTemplateHtml from './coHardwareTileTemplate.html';
export function coHardwareTile():ng.IDirective {
    return {
        scope: {
            hardware: '=coHardwareTileHardware',
            hwCompareUrl: '=coHardwareTileHwCompareUrl',
            tariffExplicitlySet: '=coHardwareTileTariffExplicitlySet',
            salesHighlight: '=coHardwareTileSalesHighlight',
            productLine: '=coHardwareTileTrackingProductLine',
            isHighlight: '=coHardwareTileHighlight'
        },
        replace: true,
        template: coHardwareTileTemplateHtml,
        controller: 'HardwareTileController',
        controllerAs: 'hardwareTileController'
    };
}
