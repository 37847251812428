import {IHwCatalogService} from '../../service/IHwCatalogService';
import {IAdaptiveService} from '@shop/common';

export class PriceLabelController {

    static $inject = ['hwCatalogService', 'adaptiveService'];

    private focus: boolean;

    constructor(public hwCatalogService: IHwCatalogService, public adaptiveService: IAdaptiveService) {
    }

    public shouldShowSmallPrice(): boolean {
        // display a small price if the price is not focused (usually one-time prices)
        // always display a small price in xs-viewports
        return !this.focus || this.adaptiveService.checkBreakpoints(['xs']);
    }
}
